import React, { Component } from 'react'
import Loading from '../../../component/Loading'
import { Link } from '@reach/router'
import { BlogsCard } from '../../Blogs/BlogsList'
import Cube from '../../../Animation/Cube'
import axios from 'axios'
export default class Blogs extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            list: [],
        }
    }
    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.get(`${window.api_host}/blogs?page=1&limit=3`);
        let list = [...this.state.list];
        let pagination = false;
        if (res.data.success) {
            list = list.concat(res.data.data);
            pagination = res.data.pagination;
        }
        this.setState({
            is_loading: false,
            list: list,
            pagination: pagination,
        }, () => {
            this.viewMore?.html("ดูเพิ่ม")
            this.viewMore?.removeClass("loading")
        })
    }

    render() {
        return (
            <section className='new-cars'>
                <div style={{ position: "relative", zIndex: 3, }}>
                    <div className='container'>
                        <h2>บทความที่มีประโยชน์ของเรา</h2>
                        <p className='text-center subtitle'>เราสร้างสรรบทความที่มีประโยชน์เกี่ยวกับรถ การใช้รถ หรืออื่นๆ ที่มีประโยชน์ให้กับลูกค้าได้รับรู้ให้มากที่สุด</p>
                    </div>
                    <div className='container' >
                        {
                            this.state.is_loading ?
                                <div style={{ paddingTop: 30 }}><Loading /></div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.list.length > 0 ?
                                <div className='row' id="car-list">
                                    {
                                        this.state.list.map((item, key) => {
                                            return (
                                                <React.Fragment key={key}>
                                                    <div className='pl-3 pr-3 mb-3 mt-3'>
                                                        <BlogsCard data={item} />
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                                : null
                        }

                        <div className='mt-5 d-flex justify-content-center align-items-center'>
                            {/* <Link to="/" className='button-53'>ดูทั้งหมด</Link> */}
                            <Link to="/blogs" className='button-53'>ดูบทความทั้งหมด <i className="fa-solid fa-arrow-right"></i></Link>

                        </div>
                    </div>
                </div>
                <Cube />
            </section >
        )
    }
}
