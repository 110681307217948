import React, { Component } from 'react'

export default class FireFly extends Component {
    constructor() {
        super();
        let list = []
        for (let index = 1; index <= 20; index++) {
            list.push(index)
        }
        this.state = {
            list: list,
        }
    }
    render() {
        return (
            <>
                {
                    this.state.list.map((item, index) => {
                        return (
                            <div className="circle-container" key={index}>
                                <div className="circle"></div>
                            </div>
                        )
                    })
                }
            </>
        )
    }
}
