import React, { Component } from 'react'
import Helper from '../../../Helper';
import { navigate } from '@reach/router';

export default class CarBrandSelector extends Component {
    render() {
        // console.log(this.props)
        if (this.props?.selectorList?.length < 0
            || !this.props.selectedType) {
            return;
        }
        return (
            <div className='row ' style={{ marginLeft: -5, marginRight: -5 }}>
                {
                    this.props.selectorList.map((item, index) => {
                        return (<div className='col col-auto mb-1' style={{ paddingLeft: 5, paddingRight: 5 }} key={index}>
                            <div className='card custom-select-card'
                                onClick={() => {
                                    let data = { ...this.props.qs }
                                    data[this.props.selectedType] = item.name;
                                    let url = Helper.getQueryStringLinkFromObject(data);
                                    navigate(url);
                                }}
                            >
                                {item.name}
                            </div>
                        </div>)
                    })
                }
            </div>
        )
    }
}
