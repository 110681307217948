import React, { Component } from 'react'

export default class Cube extends Component {
    constructor() {
        super();
        let list = []
        for (let index = 1; index <= 6; index++) {
            list.push(index)
        }
        this.state = {
            list: list,
        }
    }
    render() {
        return (
            <>
                {
                    this.state.list.map((item, index) => {
                        return (
                            <div className="cube" key={index}></div>
                        )
                    })
                }
            </>
        )
    }
}
