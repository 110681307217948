import React from 'react';
import Swal from 'sweetalert2';
import Helper from '../Helper';

export default class FormFileInput extends React.Component {
    // inputRef () {
    //     return this.$file
    // }

    render() {
        return (
            <>
                <div className="flex">
                    <label style={{ fontWeight: 'bold' }}>{this.props.title} {this.props.is_required ? <small style={{ color: 'red' }}>*</small> : null} </label>
                </div>
                <div className='mb-2'>
                    <input name="image"
                        type={"file"}
                        accept="image/*"
                        ref={(ref) => this.$file = window.$(ref)}
                        style={{ display: 'none' }}
                        id={this.props.fileName}
                        onChange={(e) => {
                            let res = Helper.readURL(window.$(e.target));
                            if (res.error) {
                                Swal.fire({
                                    title: 'Error!',
                                    text: res.message,
                                    icon: 'warinimg',
                                    confirmButtonText: 'OK!',
                                    customClass: {
                                        confirmButton: 'btn btn-primary',
                                        cancelButton: 'btn btn-light'
                                    },
                                    buttonsStyling: false,
                                    reverseButtons: true
                                })
                            }
                        }}
                    />
                    <div id="show_image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {
                            this.props.imagePath ?
                                <img id="blah" src={this.props.imagePath} alt="uploadedImage" style={{ maxWidth: '100%' }} />
                                : null
                        }
                    </div>
                    <label htmlFor={this.props.fileName}
                        type={"button"}
                        className="btn btn-primary"
                        style={{ width: '100%' }}
                    >
                        {
                            this.props.imagePath ? "เปลี่ยนรูป" : "เพิ่มรูป"
                        }
                    </label>
                </div>
            </>
        )
    }
}