import React from "react"

export default class FileInputMutiple extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            removeImages: [],
            uploadImages: [],
        }
    }
    reset() {
        this.setState({
            removeImages: [],
            uploadImages: [],
        })
    }
    getUploadImages() {
        return this.state.uploadImages;
    }
    getRemoveImages() {
        return this.state.removeImages;
    }
    readURL(input) {
        if (input.files && input.files.length > 0) {
            let prepareUploadImages = [...this.state.uploadImages];
            for (var key of Object.keys(input.files)) {
                prepareUploadImages.push(input.files[key]);
            }
            let uploadImages = [];
            uploadImages = [...prepareUploadImages]
            this.setState({
                uploadImages: uploadImages
            }, () => {
                this.$file.val('');
            })
        }
    }
    renderUpload() {
        return (
            <React.Fragment key={this.props.inputName}>
                <input name="image"
                    type={"file"}
                    accept="image/*"
                    ref={(ref) => this.$file = window.$(ref)}
                    style={{ display: 'none' }}
                    id={this.props.inputName || "images"}
                    multiple="multiple"
                    onChange={(e) => {
                        let res = window.$(e.target)
                        this.readURL(res[0])
                    }}
                />
                <label htmlFor={this.props.inputName || "images"}
                    type={"button"}
                    className="upload-button-col d-flex flex-column"
                    style={{ width: '100%', height: this.props.imageHeight || null }}
                >
                    <i className="las la-plus-circle" style={{ fontSize: 30, color: '#333' }}></i> เพิ่มรูป
                </label>
            </React.Fragment>
        )
    }
    render() {
        return (
            <React.Fragment key={this.props.inputName}>
                {
                    this.state.uploadImages
                        && this.state.uploadImages.length > 0 ?
                        this.state.uploadImages.map((uploadImage, uploadImages_i) => {
                            var reader = new FileReader();
                            reader.onload = (e) => {
                                window.$('#images_file_upload_' + this.props.inputName + uploadImages_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                            };
                            reader.readAsDataURL(uploadImage);
                            return (
                                <div className="col-md-2 mt-2" key={uploadImages_i} >
                                    <div className="mutiple_image_frame" style={{ height: 'auto' }}>
                                        <div className="image-delete">
                                            <button className="btn btn-danger btn-sm btn-icon"
                                                type="button"
                                                onClick={() => {
                                                    let uploadImages = [...this.state.uploadImages];
                                                    uploadImages.splice(uploadImages_i, 1);
                                                    this.setState({
                                                        uploadImages: uploadImages
                                                    })
                                                }}
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                        <div className="upload-button-col" style={{ height: this.props.imageHeight || null }}>
                                            <div className="image-cover" id={"images_file_upload_" + this.props.inputName + uploadImages_i} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        : null
                }
                {
                    this.props.moreImages ?
                        this.props.moreImages.map((image, i) => {
                            let check = this.state.removeImages.filter((e) => { return e.storagePath == image.storagePath });
                            let isRender = true;
                            if (check && check.length > 0) {
                                isRender = false;
                            }
                            if (!isRender) return;
                            return (
                                <div className="col-md-2 mt-2" key={i} >
                                    <div className="mutiple_image_frame" style={{ height: 'auto' }}>
                                        <div className="image-delete">
                                            <button className="btn btn-danger btn-sm btn-icon"
                                                type="button"
                                                onClick={() => {
                                                    let removeImages = [...this.state.removeImages];
                                                    removeImages.push(image);
                                                    this.setState({
                                                        removeImages: removeImages
                                                    })
                                                }}
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                        <div className="upload-button-col" style={{ height: this.props.imageHeight || null }}>
                                            <div className="image-cover" id={"images_file_car_" + i} >
                                                <img src={image.imagePath} alt={"More car images"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : null
                }

                <div className="col-md-2 mt-2">
                    {this.renderUpload()}
                </div>
            </React.Fragment>
        )
    }
}