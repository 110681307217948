import React, { Component } from 'react';
import PageHeader from '../../Include/PageHeader';
import ProvacyInfo from './../../component/PrivacyInfo'

export default class Policy extends Component {
    render() {
        return (
            <div style={{ position: 'relative' }}>
                <PageHeader title={"Privacy and Policy"} />
                <section className='about-us pt-0'>
                    <div className='container mt-3' style={{ position: 'relative' }}>
                        <ProvacyInfo />
                    </div>
                </section>
            </div>
        )
    }
}
