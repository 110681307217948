import React, { Component } from 'react'
import Cube from './../../../Animation/Cube'
import Loading from '../../../component/Loading';
import axios from 'axios'
import Helper from '../../../Helper';
import { Link } from '@reach/router';
export default class NewCars extends Component {
  constructor() {
    super();
    this.state = {
      is_loading: true,
      list: []
    }
  }

  updateCars(cars) {
    this.setState({
      is_loading: false,
      list: cars
    })
  }



  render() {

    return (
      <section className='new-cars'>
        <div style={{ position: "relative", zIndex: 3, }}>
          <h2>New Cars</h2>
          <div className='container' >
            {
              this.state.is_loading ?
                <div style={{ paddingTop: 30 }}><Loading /></div>
                : null
            }
            {
              !this.state.is_loading
                && this.state.list.length > 0 ?
                <div className='row newcar-list justify-content-center'>
                  {
                    this.state.list.map((data, index) => {
                      return (
                        <div key={index} className='col-6 col-sm-4 col-md-4 newcar-item'>
                          <Link to={`/car/${data.id}`} className='newcar-card'>

                            {
                              data.is_reserve ?
                                <div className="ribbon ribbon-top-left" style={{ left: -10 }}><span>ติดจอง</span></div>
                                : null
                            }
                            <div className='newcar-card-image'>
                              {
                                data.status.id == '1' ?
                                  <div className='custom-ribbon '><i className="las la-wrench"></i> กำลังซ่อม</div>
                                  : null
                              }
                              {
                                data.fileImageCar?.imagePath ?
                                  <img src={data.fileImageCar?.thumbnailURL350 || data.fileImageCar?.imagePath} alt={"car"} />
                                  : null
                              }

                            </div>
                            <div className='newcar-card-info'>
                              <div className='d-flex w-100 align-items-center flex-wrap'>
                                <h3>{data.brand}</h3>
                                <div className='ml-auto'>
                                  <small><i className="fa-solid fa-gauge-high"></i>  {Helper.numberFormat(data.mile || 0)} km, {data.year ? `${data.year}` : null}</small>
                                </div>
                              </div>
                              <p>
                                {data.model}
                                {` `}
                                {`ปี ${data.year} `}
                                {data.submodel || data.minor ? <><span className="bullet bullet-dot"></span></> : null}
                                {data.minor ? ` ${data.minor}` : null}
                                {data.submodel ? ` ${data.submodel}` : null}
                                {` `}
                              </p>
                              <div className='price'>{Helper.numberFormat(data.sellingPrice)} <small className='text-muted'>บาท</small></div>

                            </div>
                          </Link>
                        </div>
                      )
                    })
                  }

                </div>
                : null
            }

          </div>
        </div>
        <Cube />
      </section >
    )
  }
}
