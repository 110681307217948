import React, { Component } from 'react'
import Helper from '../../../Helper';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import axios from 'axios';
export default class CarFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'grip',
            options: props.options,
            is_loading: false,
        }
    }

    getModelSelect(value) {
        let data = []
        if (!value && this.$model) {
            this.$model.find('option').remove()
            this.$model.select2({
                allowClear: true,
                placeholder: "รุ่น",
                data: null
            })
            this.$model.empty().val(null).trigger("change");
            return;
        }
        let ref = window.$('[name="model"]');
        ref.find('option').remove()
        let brand = this.state.options.brand.filter((e) => { return e.name == value })[0];
        if (brand) {
            let models = this.state.options.model.filter((e) => { return e.brandUid == brand.id });
            for (const model of models) {
                data.push({ text: model.name, id: model.name })
            }
        }
        if (this.$model) {
            this.$model.select2({
                allowClear: true,
                placeholder: "รุ่น",
                data: data
            })
            this.$model.val(null).trigger('change');
            if (this.props.qs.model) {
                this.$model.val(this.props.qs.model).trigger('change');
            }
        }
    }
    getMinorSelect(value) {
        let data = []
        if (!value && this.$minor) {
            this.$minor.find('option').remove()
            this.$minor.select2({
                allowClear: true,
                placeholder: "โฉมรถ",
                data: null
            })
            this.$minor.empty().val(null).trigger("change");
            return;
        }
        let ref = window.$('[name="minor"]');
        ref.find('option').remove()
        let model = this.state.options.model.filter((e) => { return e.name == value })[0];
        if (model) {
            let minors = this.state.options.minor.filter((e) => { return e.modelUid == model.id });
            for (const minor of minors) {
                data.push({ text: minor.name, id: minor.name })
            }
        }
        if (this.$minor) {
            this.$minor.select2({
                allowClear: true,
                placeholder: "โฉมรถ",
                data: data
            })
            this.$minor.val(null).trigger('change');
            if (this.state.options.minor) {
                this.$minor.val(this.state.options.minor).trigger('change');
            }
        }
    }

    getSubModelSelect(value) {
        let data = []
        if (!value && this.$submodel) {
            this.$submodel.find('option').remove()
            this.$submodel.select2({
                allowClear: true,
                placeholder: "รุ่นย่อย",
                data: null
            })
            this.$submodel.empty().val(null).trigger("change");
            return;
        }
        let ref = window.$('[name="submodel"]');
        ref.find('option').remove()
        let minor = this.state.options.minor.filter((e) => { return e.name == value })[0];
        if (minor) {
            let submodels = this.state.options.submodel.filter((e) => { return e.minorUid == minor.id });
            for (const model of submodels) {
                data.push({ text: model.name, id: model.name })
            }
        }
        if (this.$submodel) {
            this.$submodel.select2({
                allowClear: true,
                placeholder: "รุ่นย่อย",
                data: data
            })
            this.$submodel.val(null).trigger('change');
            if (this.props.qs.submodel) {
                this.$submodel.val(this.props.qs.submodel).trigger('change');
            }
        }
    }
    render() {
        let options = this.state.options;
        let min = this.state.options?.min || 0;
        let max = this.state.options?.max || 0;
        return (
            <div ref={(ref) => { this.$searchContainer = window.$(ref) }} className='search-cars-container'>
                <div className='backdrop'
                    onClick={() => {
                        this.$searchContainer.removeClass('open')
                    }}
                />
                <div className='search-cars '>
                    {/* {this.renderShowBtnOnResponsive()} */}
                    <div className="modal-dialog mt-0" role="document">
                        <form className="modal-content modal-sm car-card border-0" style={{ ...customModal.modal }}
                            ref={(ref) => {
                                this.$form = window.$(ref);
                                window.$(ref).off().on('submit', (e) => {
                                    e.preventDefault();
                                    let data = Helper.getFormInputObject(this.$form);
                                    // console.log(data)
                                    this.props.onSubmit(data);
                                    this.$searchContainer.removeClass('open')
                                    // this.$modal.modal('hide');

                                })
                            }}
                        >
                            {
                                this.props.qs?.orderBy ?
                                    <input name="orderBy" type="hidden" value={this.props.qs.orderBy} />
                                    : null
                            }
                            <div className="modal-header pb-0" style={{ ...customModal.header }}>
                                <h4 className="modal-title" style={{ ...customModal.headerTitle }}>ค้นหารถ</h4>
                                {/* <div className='modal-tools show-resp'>


                                    <button
                                        ref={(ref) => {
                                            window.$(ref).off().on('click', () => {
                                                this.$modal.modal('hide')
                                            })
                                        }}
                                        type="button"
                                        className="btn btn-light btn-icon "
                                        style={{
                                            borderRadius: 50,
                                        }}
                                    ><i className='fas fa-times' style={{ fontWeight: 'bold' }} /></button>
                                </div> */}
                            </div>
                            <div className="modal-body bold-label" >
                                {
                                    this.state.is_loading ?
                                        <div className="d-flex justify-content-center align-items-center mb-5">
                                            <span className="spinner-border align-middle me-3"></span>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.options ?
                                        <>
                                            <div className='row'>
                                                <div className='col-12 mb-2'>
                                                    <label>ยี่ห้อ</label>
                                                    <select
                                                        ref={(ref) => {
                                                            this.$brand = window.$(ref)
                                                            this.$brand.select2({
                                                                allowClear: true,
                                                                placeholder: "ยี่ห้อ",
                                                            })
                                                            if (this.props.qs.brand && this.props && this.state.options) {
                                                                this.$brand.val(this.props.qs.brand).trigger('change');
                                                                // this.getModelSelect(this.props.qs.brand);
                                                            } else {
                                                                this.$brand.val(null).trigger('change');
                                                            }
                                                            this.$brand.on('select2:select', (e) => {
                                                                this.getModelSelect(e.target.value)
                                                                this.$form.trigger('submit')
                                                            })

                                                            this.$brand.on('select2:clear', () => {
                                                                this.getModelSelect()
                                                                this.getMinorSelect()
                                                                this.getSubModelSelect()
                                                                this.$form.trigger('submit')
                                                            })
                                                        }}
                                                        className="form-select"
                                                        id="brand"
                                                        name="brand"
                                                        required={false}
                                                        autoComplete={"off"}
                                                    >
                                                        {
                                                            this.state.options.brand.map((brand, i) => {
                                                                return (
                                                                    <option value={brand.name} key={i}>{brand.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className='col-12 mb-2'>
                                                    <label>รุ่น</label>
                                                    <select
                                                        ref={(ref) => {
                                                            this.$model = window.$(ref);
                                                            this.$model.select2({
                                                                allowClear: true,
                                                                placeholder: "รุ่น",
                                                            })
                                                            this.$model.val(null).trigger('change');
                                                            if (this.props.qs.brand) {
                                                                this.getModelSelect(this.props.qs.brand);
                                                            }
                                                            this.$model.on('select2:select', (e) => {
                                                                this.getMinorSelect(e.target.value)
                                                                this.$form.trigger('submit')
                                                            })
                                                            if (this.props.qs.model && this.props && this.state.options) {
                                                                this.$model.val(this.props.qs.model).trigger('change');
                                                            }

                                                            this.$model.on('select2:clear', () => {
                                                                this.getMinorSelect()
                                                                this.getSubModelSelect()
                                                                this.$form.trigger('submit')
                                                            })
                                                        }}
                                                        className='form-select mb-3'
                                                        name="model"
                                                    >
                                                    </select>
                                                </div>
                                                <div className='col-12 mb-2'>
                                                    <label>โฉม</label>
                                                    <select
                                                        ref={(ref) => {
                                                            this.$minor = window.$(ref);
                                                            this.$minor.select2({
                                                                allowClear: true,
                                                                placeholder: "โฉมรถ",
                                                            })
                                                            this.$minor.val(null).trigger('change');
                                                            if (this.props.qs.model) {
                                                                this.getMinorSelect(this.props.qs.model);
                                                            }
                                                            this.$minor.on('select2:select', (e) => {
                                                                this.getSubModelSelect(e.target.value)
                                                                this.$form.trigger('submit')
                                                            })
                                                            if (this.props.qs.minor && this.props && this.state.options) {
                                                                this.$minor.val(this.props.qs.minor).trigger('change');
                                                            }
                                                            this.$minor.on('select2:clear', () => {
                                                                this.getSubModelSelect()
                                                                this.$form.trigger('submit')
                                                            })
                                                        }}
                                                        className='form-select mb-3'
                                                        name="minor"
                                                    >
                                                    </select>
                                                </div>
                                                <div className='col-12 mb-2'>
                                                    <label>รุ่นย่อย</label>
                                                    <select
                                                        ref={(ref) => {
                                                            this.$submodel = window.$(ref);
                                                            this.$submodel.select2({
                                                                allowClear: true,
                                                                placeholder: "รุ่นย่อย",
                                                            })
                                                            this.$submodel.val(null).trigger('change');
                                                            if (this.props.qs.minor) {
                                                                this.getSubModelSelect(this.props.qs.minor);
                                                            }
                                                            if (this.props.qs.submodel && this.props && this.state.options) {
                                                                this.$submodel.val(this.props.qs.submodel).trigger('change');
                                                            }
                                                            this.$submodel.on('select2:select', (e) => {
                                                                this.$form.trigger('submit')
                                                            })
                                                            this.$submodel.on('select2:clear', () => {
                                                                this.$form.trigger('submit')
                                                            })
                                                        }}
                                                        className='form-select mb-3'
                                                        name="submodel"
                                                    >
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-12 mb-2'>
                                                    <label>สี</label>
                                                    <select
                                                        ref={(ref) => {
                                                            window.$(ref).select2({ allowClear: true, placeholder: 'สี' });
                                                            if (this.props.qs && this.props.qs.color) {
                                                                window.$(ref).val(this.props.qs.color).trigger('change')
                                                            } else {
                                                                window.$(ref).val(null).trigger('change');
                                                            }
                                                            window.$(ref).on('clear', () => {
                                                                window.$(ref).val(null).trigger('change');
                                                            })
                                                            window.$(ref).on('select2:select', (e) => {
                                                                this.$form.trigger('submit')
                                                            })
                                                            window.$(ref).on('select2:clear', () => {
                                                                this.$form.trigger('submit')
                                                            })
                                                        }}
                                                        className="form-select"
                                                        id="color"
                                                        name="color"
                                                        required={false}
                                                        autoComplete={"off"}
                                                    >
                                                        {
                                                            this.state.options.color.map((color, i) => {
                                                                return (
                                                                    <option value={color} key={i}>{color}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                            <div>
                                                <label>ช่วงราคา</label>
                                                <SlideRange
                                                    qs={this.props.qs}
                                                    min={min}
                                                    max={max}
                                                    submitForm={() => {
                                                        this.$form.trigger('submit')
                                                    }}
                                                />
                                            </div>
                                        </>
                                        : null
                                }

                            </div>
                            {
                                !this.state.is_loading ?
                                    <div className="modal-footer">
                                        <div className='d-flex' style={{ ...customModal.headerFooter }}>

                                            <button
                                                ref={(ref) => {
                                                    window.$(ref).off().on('click', () => {
                                                        this.setState({
                                                            is_loading: true,
                                                        }, () => {
                                                            this.props.onSubmit({});
                                                            this.setState({
                                                                is_loading: false,
                                                            })
                                                        })
                                                        // this.$modal.modal('hide');
                                                    })
                                                }}
                                                type="button"
                                                className="btn btn-light"
                                                style={{ ...customModal.button }}
                                            >รีเซ็ท</button>
                                            <div>

                                                {/* <button
                                                ref={(ref) => {
                                                    window.$(ref).off().on('click', () => {
                                                        this.$modal.modal('hide')
                                                    })
                                                }}
                                                type="button"
                                                className="btn btn-light"
                                                style={{ ...customModal.button }}
                                            >ยกเลิก</button> */}
                                                <button
                                                    ref={(ref) => {
                                                        this.$submit = window.$(ref);
                                                    }}
                                                    type="submit"
                                                    className='btn btn-primary ml-3'
                                                    style={{ ...customModal.button }}
                                                >
                                                    ค้นหา
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </form>
                    </div>
                </div>
            </div>
        )
    }
    // renderShowBtnOnResponsive() {
    //     return (
    //         <div className='btn-toggle-menu-container show-resp'>
    //             <button
    //                 onClick={() => {
    //                     let containerRef = window.$('.search-cars-container');
    //                     if (containerRef.hasClass('open')) {
    //                         containerRef.removeClass('open')
    //                     } else {
    //                         containerRef.addClass('open')
    //                     }
    //                 }}
    //                 className='car-card btn-toggle-menu '
    //             >
    //                 <i className="las la-angle-right closedIcon"></i>
    //                 <i className="las la-angle-left openedIcon"></i>
    //             </button>
    //         </div>
    //     )
    // }
}

class SlideRange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            min: props.qs?.priceMin || props.min,
            max: props.qs?.priceMax || props.max,
        }
        this.rangeSlider = React.createRef();
    }
    render() {
        return (
            <>
                <div className='mb-4'>
                    <RangeSlider
                        ref={this.rangeSlider}
                        value={[this.state.min, this.state.max]}
                        step={10000}
                        min={this.props.min || 0}
                        max={this.props.max || 0}
                        onInput={(e) => {
                            let min = e[0] || 0;
                            let max = e[1] || 0;
                            this.setState({
                                min: min,
                                max: max
                            })

                        }}
                        onThumbDragEnd={() => {
                            if (this.props.submitForm) {
                                this.props.submitForm()
                            }
                        }}
                        rangeSlideDisabled={true}
                    />
                    <p className='mt-3' style={{ fontWeight: 'bold' }}>฿ {Helper.numberFormat(this.state.min)} - {Helper.numberFormat(this.state.max)}</p>
                </div>
                <div className='row'>
                    <div className='col-6 mb-2'>
                        <input
                            type={"hidden"}
                            name="priceMin"
                            readOnly={true}
                            className={"form-control"}
                            style={{ borderRadius: 15 }}
                            value={this.state.min}
                            onChange={(e) => {
                                let value = e.target.value || 0;
                                this.setState({
                                    min: value
                                }, () => {
                                    this.rangeSlider.value.min = value
                                })
                            }}
                        />
                    </div>
                    <div className='col-6 mb-2'>
                        <input
                            type={"hidden"}
                            name="priceMax"
                            readOnly={true}
                            className={"form-control"}
                            style={{ borderRadius: 15 }}
                            value={this.state.max}
                            onChange={(e) => {
                                let value = e.target.value || 0;
                                this.setState({
                                    max: value
                                }, () => {
                                    this.rangeSlider.value.max = value
                                })
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
}
const customModal = {
    modal: {
        borderRadius: 15,
        width: 300,
    },
    header: {
        borderBottomWidth: 0,
    },
    headerTitle: {
        fontWeight: 'bold'
    },
    headerFooter: {
        width: `100%`,
        justifyContent: `space-between`
    },
    button: {
        borderRadius: 15,
        padding: '10px 20px'
    },
    input: {
        borderRadius: 15
    },
}