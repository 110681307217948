import React, { Component } from 'react'
import PageHeader from '../../Include/PageHeader';
import AboutUsInfo from '../Home/include/AboutUs'

export default class AboutUs extends Component {

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <PageHeader title={"Cars & Family"} />
                <section className='about-us pt-0'>
                    <div className='container mt-3' style={{ position: 'relative' }}>
                        {/* <h2 className='text-center mb-4' style={{ fontWeight: 'bold' }}>บริษัท Champ Earth Motors ,Cars and Family</h2> */}
                        <div className='car-carousel-item mb-3 d-flex justify-content-center align-items-center'>
                            <img src={`${window.location.origin}/assets/images/cover.jpg`} style={{ maxWidth: '100%' }} />
                        </div>
                        <AboutUsInfo />
                        {/* <p className='text-center text-black'>
                            <br />
                            <h2 className='custom-about-text mb-0'>Our company</h2>
                            <h2 className='custom-about-text mb-3'><small>"ทำรถ เพราะ ชอบรถ"</small></h2>
                            พวกเราเป็นคนรุ่นใหม่ที่ชอบรถ ซึ่งประกอบธุรกิจ เกี่ยวกับ การซื้อ-ขาย รถยนต์ มือสอง (premium segment เช่น Benz,BMW,Porsche,อื่นๆ )<br />
                            ในรูปแบบที่พวกเราชอบ เพราะพวกเราก็ชอบรถที่ดี ,สภาพสวย,ใช้งานได้จริง และราคาสมเหตุสมผลเช่นกัน เหมือนลูกค้าทุกท่านครับ<br />
                            <br />
                            <h2 className='custom-about-text mb-0'>We</h2>
                            <h2 className='custom-about-text mb-3'><small>"บุคลากรของพวกเรา"</small></h2>
                            พนักงานทุกคนที่นี่ โดยเฉพาะพนักงานขาย ลากไปจนถึงเจ้าของ ล้วนแล้วแต่มีประสบการณ์"โชกโชน" และ passion ในงานขายรถแบรนด์หรูหรามาทั้งหมด<br />
                            เพราะฉะนั้นเรื่องข้อมูลเกี่ยวกับตัวรถและการบริการ ไม่ต้องห่วง แต่อย่างใดครับ<br />
                            รับรองว่าประสบการณ์ "แน่นๆ"กันทุกคน<br />
                            <br />
                            <h2 className='custom-about-text mb-0'>Our way to select cars</h2>
                            <h2 className='custom-about-text mb-3'><small>"หลักเกณฑ์ในการเลือกรถของพวกเรา"</small></h2>
                            รถทุกคันที่พวกเราเลือกมาซื้อ-ขายนั้น เวลาเลือกมาเปรียบเสมือน "เลือกมาเพื่อซื้อใช้งานเองหรือซื้อให้คนที่เรารัก-คนในครอบครัวใช้" ( เอาชนิดที่ว่าถ้าขายไม่ออกก็ใช้กันเองนี่แหละ 555 ) เพราะฉะนั้น<br />
                            คุณภาพมาก่อนแน่นอนครับและคุ้มกับเงินทุกบาททุกสตางค์ที่คุณจ่ายไปแน่นอน เรื่องชนหนัก,จมน้ำไม่ต้องพูดถึงครับ! ตัดทิ้งได้เลย รถทุกคันมีประวัติให้ดูและตรวจสอบได้แน่นอนครับ<br />
                            <br />

                            <h2 className='custom-about-text mb-0'>Our Business core values</h2>
                            เราไม่ใช่พ่อค้าที่ ขายถูกที่สุด หรือ ซื้อแพงที่สุด ในประเทศ แต่ เรา สามารถพูดได้เต็มปากว่าสินค้าของเรามีคุณภาพและสมเหตุสมผลในเชิงของราคาแน่นอน<br />
                            <br />

                            <h2 className='custom-about-text mb-0'>Our promise</h2>
                            เราขอสัญญาว่า เราจะใช้ประสบการณ์ของพวกเราเพื่อ ดูแลลูกค้าทุกคน ที่ไม่ว่าจะซื้อหรือขายรถกับที่นี่ให้ เกิดเป็นความทรงจำที่ดีดีที่สุด เท่าที่พวกเราจะทำได้ และกลับมาซื้อซ้ำ หรือแนะนำเพื่อนๆ มาอุดหนุนพวกเราใหม่ อิอิ

                        </p> */}
                        <hr className='mt-5 mb-5' />
                        <p className='text-center text-black'>รับซื้อ - ขาย รถยุโรป มือสอง สภาพดี</p>
                        <p className='text-center text-black'>การันตีประวัติทุกคัน ไมล์แท้ ไม่มีชนหนัก ไม่มีย้อมแมว</p>
                        <div className='d-flex justify-content-center align-items-center flex-wrap'>
                            <p className='text-center text-black mb-0'>Tel. 099 146 4646</p>
                            <a href="https://lin.ee/2BwZQnijS" target={"_blank"} className='d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link'>
                                <img src={`${window.location.origin}/assets/images/social/line.png`} style={{ height: 40 }} />
                                <p className='ml-1 mb-0'>@Champearthmotors</p>
                            </a>
                        </div>
                        <div className='d-flex justify-content-center align-items-center mt-0 flex-wrap'>
                            <a href="https://www.youtube.com/@champearthmotors3741/videos" target={"_blank"} className='d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link'>
                                <img src={`${window.location.origin}/assets/images/social/youtube.png`} style={{ height: 40 }} />
                                <p className='ml-1 mb-0'>ChampEarth Motors</p>
                            </a>
                            <a href="https://www.facebook.com/ChampEarthMotors" target={"_blank"} className='d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link'>
                                <img src={`${window.location.origin}/assets/images/social/facebook.png`} style={{ height: 40 }} />
                                <p className='ml-1 mb-0'>ChampEarth Motors</p>
                            </a>
                            <a href="https://www.instagram.com/champearth_motors/" target={"_blank"} className='d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link'>
                                <img src={`${window.location.origin}/assets/images/social/instagram.png`} style={{ height: 40 }} />
                                <p className='ml-1 mb-0'>ChampEarth_Motors</p>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

