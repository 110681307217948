import React, { Component } from 'react';
import { Link } from '@reach/router'
import Loading from '../../../component/Loading';
import Helper from '../../../Helper';
import CarCard from './../../../component/CarCard'

export default class OursCars extends Component {
  constructor() {
    super();
    this.state = {
      is_loading: true,
      list: [],
    }
  }

  updateCars(cars) {
    this.setState({
      is_loading: false,
      list: cars
    })
  }




  render() {
    return (
      <section className='ourscars'>
        <h2 className='mb-5'>Ours Cars</h2>
        <div className='container'>
          {
            this.state.is_loading ?
              <div style={{ paddingTop: 30 }}><Loading /></div>
              : null
          }
          {
            !this.state.is_loading
              && this.state.list.length > 0 ?
              <div className='row'>
                {
                  this.state.list.map((data, key) => {
                    return (
                      <div className='col-6 col-md-4 mb-4' key={key}>
                        <CarCard
                          data={data}
                        />
                      </div>
                    )
                  })
                }
              </div>
              : null
          }
        </div>

        <div className='mt-5 d-flex justify-content-center align-items-center'>
          {/* <Link to="/" className='button-53'>ดูทั้งหมด</Link> */}
          <Link to="/cars" className='button-53'>ดูทั้งหมด <i className="fa-solid fa-arrow-right"></i></Link>

        </div>
      </section>
    )
  }
}
