import React from 'react';
import Helper from '../Helper';
export default class NumberFormatInput extends React.Component {
    constructor(props) {
        super(props);
        let value = 0;
        let defaultValue = '';
        if (props.defaultValue) {
            defaultValue = Helper.numberFormat(props.defaultValue);
            value = props.defaultValue;
        }
        this.state = {
            defaultValue: defaultValue,
            value: value
        }
    }
    setDefaultValue(amount) {
        let value = 0;
        let defaultValue = '';
        console.log(amount)
        if (amount) {
            defaultValue = Helper.numberFormat(amount);
            value = amount;
        }
        this.state = {
            defaultValue: defaultValue,
            value: value
        }
    }
    resetValue() {
        this.setState({
            defaultValue: '',
            value: 0
        })
    }
    render() {
        return (
            <>
                <input
                    className={this.props.className}
                    placeholder={this.props.placeholder}
                    required={this.props.required || false}
                    value={this.state.defaultValue}
                    onChange={(e) => {
                        let value = '';
                        if (e.target.value) {
                            value = e.target.value.replace(/,/g, "");
                        }
                        let defaultValue = '';
                        if (value) {
                            defaultValue = Helper.numberFormat(value);
                        }
                        this.setState({
                            value: value,
                            defaultValue: defaultValue,
                        }, () => {
                            if (this.props.onChange) {
                                this.props.onChange(e)
                            }
                        })
                    }}
                />
                <input type="hidden" name={this.props.name} value={this.state.value} />
            </>
        )
    }
}