// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAr2E3_UWtUVOrEDThX2J_sKrL6R7mkXmA",
    authDomain: "champearthmotor-csi.firebaseapp.com",
    databaseURL: "https://champearthmotor-csi-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "champearthmotor-csi",
    storageBucket: "champearthmotor-csi.appspot.com",
    messagingSenderId: "302688517160",
    appId: "1:302688517160:web:1b4d1d68b8f9643d13c91d",
    measurementId: "G-473DH880E3"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// export const auth = app.auth();
// export const db = app.firestore();
export const storage = getStorage(app);
