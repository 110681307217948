import React, { Component } from 'react';
import Helper from '../../../Helper';
import RangeSlider from 'react-range-slider-input';

import Slider from '@mui/material/Slider';
export default class FinanceCalculator extends Component {
    constructor(props) {
        super(props);
        let data = props.data;
        // data.sellingPrice = 1000000;
        this.state = {
            min: 0,
            max: 80,
            data: data,
            sellingPrice: data.sellingPrice,
            percentage: 25,
            monthRate: {
                installment: 24,
                interest: 2.750,
            },
        };
        this.rangeSlider = React.createRef();
        this.InstallmentRate = React.createRef();
    }

    componentDidMount() {
        this.genarateGraph();
    }

    genarateGraph() {
        let car_price = this.state.sellingPrice;
        let percent = this.state.percentage;
        let down_payment = (car_price * percent) / 100;
        let payment_total = car_price - down_payment;
        var interest_value = ((payment_total * this.state.monthRate.interest) / 100) * (this.state.monthRate.installment / 12);


        window.$("#graph").html("");
        window.Morris.Donut({
            element: 'graph',
            resize: true,
            colors: ["#f56954", "#00a65a", "#3c8dbc"],
            data: [
                { label: "ดอกเบี้ยทั้งหมด", value: interest_value },
                { label: "เงินดาวน์", value: down_payment },
                { label: "ยอดจัด", value: payment_total },
            ],
            hideHover: 'auto',
            formatter: function (x) { return Helper.numberFormat(x.toFixed(2), { deciaml: 2 }) }
        }).on('click', function (i, row) {
            // console.log(i, row);
        });
        if (this.InstallmentRate.current.updateState) {
            this.InstallmentRate.current.updateState({
                car_price: car_price,
                down_payment: down_payment,
                payment_total: payment_total,
                interest_value: interest_value,
            })
        }
        window.$(`#downPercentage`).html(`${percent}%`)
    }

    render() {
        let data = this.state.data;
        return (
            <div>
                <hr className='hr-color mb-5' />
                <h3 className="mb-2 text-left title-after" style={{ fontWeight: 'bold' }}>สินเชื่อไฟแนนซ์</h3>
                <p className='mb-2 ttext-left text-bold'>
                    <span className='text-muted'>ราคารถยนต์ : </span>
                    {
                        data.sellingPrice ?
                            <span className='text-black'>{`฿${Helper.numberFormat(data.sellingPrice)}`}</span>
                            : null
                    }
                </p>
                <div className='row'>
                    <div className='col-12 col-lg-7'>
                        <div id="graph" />
                        <div className='text-center'>
                            แสดงในอัตราผ่อน 72 งวด รวมเป็นเงินทั้งหมด 2,327,325 บาท
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12 col-lg-3 d-flex justify-content-center align-items-center'>
                                <p className='text-muted mb-1 d-flex justify-content-center align-items-center'>เงินดาวน์ (%)</p>
                            </div>
                            <div className='col-12 col-lg-9'>
                                <Slider
                                    size="medium"
                                    defaultValue={this.state.percentage}
                                    scale={(x) => {
                                        return `${x}%`;
                                    }}
                                    min={this.state.min}
                                    max={this.state.max}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                    aria-labelledby="non-linear-slider"
                                    onChange={(event, number) => {
                                        this.setState({
                                            percentage: number,
                                        }, () => {
                                            this.genarateGraph()
                                        })
                                    }}
                                // onChangeCommitted={(event, number) => {

                                // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-5'>
                        <InstallmentRate
                            ref={this.InstallmentRate}
                            sellingPrice={data.sellingPrice}
                            onChangeYear={(rate) => {
                                this.setState({
                                    monthRate: rate
                                }, () => {
                                    this.genarateGraph()
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
class InstallmentRate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            car_price: props.sellingPrice,
            down_payment: 0,
            payment_total: 0,
            interest_value: 0,
            installmentList: [
                {
                    installment: 24,
                    interest: 2.790,
                },
                {
                    installment: 36,
                    interest: 2.790,
                },
                {
                    installment: 48,
                    interest: 2.790,
                },
                {
                    installment: 60,
                    interest: 2.990,
                },
                {
                    installment: 72,
                    interest: 3.990,
                },
            ],
            installmentSelected: 24
        }
    }
    updateState(state) {
        this.setState({
            ...state
        })
    }
    render() {
        let vat = (this.state.payment_total * 7) / 100;
        return (
            <>
                <div className='card-finance'>
                    <div className='card-finance-title mb-0'>
                        <h3 className='mb-0'>อัตรการผ่อนชำระ (รวมภาษี 7%)</h3>
                    </div>
                    <hr className='hr-color mb-0 mt-0' />
                    <div className='card-finance-subtitle mb-0'>
                        <div className='d-flex'>
                            <p className='mb-0'>เงินดาวน์ <span id="downPercentage">14%</span></p>
                            <div className='floated-right'>
                                <p className='mb-0'>฿{Helper.numberFormat(this.state.down_payment)}</p>
                            </div>
                        </div>
                    </div>
                    <hr className='hr-color mb-0 mt-0' />
                    <div className='card-finance-subtitle mb-0'>
                        <div className='d-flex'>
                            <p className='mb-0'>ยอดจัด</p>
                            <div className='floated-right'>
                                <p className='mb-0'>฿{Helper.numberFormat(this.state.payment_total)}</p>
                            </div>
                        </div>
                    </div>
                    <hr className='hr-color mb-0 mt-0' />
                    {/* <div className='card-finance-subtitle mb-0'>
                        <div className='d-flex'>
                            <p className='mb-0'>ภาษี</p>
                            <div className='floated-right'>
                                <p className='mb-0'>฿{Helper.numberFormat(vat)}</p>
                            </div>
                        </div>
                    </div> */}
                    {
                        this.state.installmentList.map((item, index) => {
                            let payment_total = this.state.payment_total
                            var interest_value = ((payment_total * item.interest) / 100) * (item.installment / 12);
                            // let vat_per_installment = vat * (item.installment / 12);
                            payment_total = payment_total;
                            let current_vat = ((payment_total + interest_value) * 7) / 100
                            // console.log("interest_value", interest_value)
                            // console.log("vat_per_installment", vat_per_installment)
                            var amount = Math.ceil((interest_value + payment_total + current_vat) / item.installment);
                            return (
                                <React.Fragment key={index}>
                                    <hr className='hr-color mb-0 mt-0' />
                                    <div
                                        className={`card-finance-item hover mb-0 ${item.installment == this.state.installmentSelected ? "active" : ""}`}
                                        onClick={() => {
                                            this.setState({
                                                installmentSelected: item.installment
                                            }, () => {
                                                if (this.props.onChangeYear) {
                                                    this.props.onChangeYear(item)
                                                }
                                            })
                                        }}
                                    >
                                        <div className='d-flex'>
                                            <p className='mb-0'>{`${item.installment} งวด (ดอกเบี้ย ${Helper.numberFormat(item.interest)}%)`}</p>
                                            <div className='floated-right'>
                                                <p className='mb-0'>฿{Helper.numberFormat(amount)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </>
        )
    }
}