import React, { Component } from 'react'
import PageHeader from '../../Include/PageHeader';
import { navigate } from '@reach/router';
import axios from 'axios';
import Helper from '../../Helper';
import Swal from 'sweetalert2';

export default class SaleCarPhone extends Component {
    constructor() {
        super();
        this.state = {
            phoneNumber: ''
        }
    }

    async requestOTP() {
        let res = await axios.post(`${window.api_host}/web/otp/request/${this.state.phoneNumber}`);

        if (res.data.success) {
            // Swal.fire({
            //     title: 'success!',
            //     text: `success`,
            //     icon: 'success',
            //     confirmButtonText: 'OK!',
            //     customClass: {
            //         confirmButton: 'btn btn-primary',
            //         cancelButton: 'btn btn-light'
            //     },
            //     buttonsStyling: false,
            //     reverseButtons: true
            // })
            navigate(`/sale/car/confirm-otp?phoneNumber=${this.state.phoneNumber}`)
        } else {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด!',
                text: res?.data?.message || `กรุณาลองใหม่อีกครั้ง`,
                icon: 'error',
                confirmButtonText: 'OK!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-light'
                },
                buttonsStyling: false,
                reverseButtons: true
            })
            return;
        }
    }

    render() {
        return (
            <section className='sale-car-form'>
                <PageHeader title={"ขายรถและประเมินราคา"} />
                <div className='container'>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.viewMore.hasClass('loading')) return;
                            this.viewMore.addClass('loading');
                            this.viewMore.html("กำลังโหลดข้อมูล...")
                            this.requestOTP();
                        }}
                        className='sale-car-box d-flex flex-column justify-content-center align-items-center'
                    >
                        <h4 className='mb-3'>กรุณากรอกเบอร์โทรศัพท์</h4>
                        <input name="phone"
                            maxLength={10}
                            required={true}
                            onChange={(e) => {
                                let phoneNumber = 0;
                                if (!isNaN(e.target.value)) {
                                    phoneNumber = e.target.value;
                                } else {
                                    phoneNumber = this.state.phoneNumber;
                                    window.$(`input[name="phone"]`).val(phoneNumber)
                                }

                                this.setState({
                                    phoneNumber: phoneNumber,
                                })
                            }}
                        />

                        <button
                            ref={(ref) => {
                                this.viewMore = window.$(ref);
                            }}
                            className='button-53 mt-5'
                            type="submit"
                        >ยืนยัน</button>
                    </form>
                </div>
            </section>
        )
    }
}
