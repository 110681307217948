import React, { Component } from 'react'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
    TwitterShareButton,
    LineShareButton,
    LineIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share';

export default class Shared extends Component {
    render() {
        console.log(window.location)
        return (
            <>
                <FacebookShareButton
                    url={`${window.location.href}`}
                    quote={'แชร์ Link!'}
                    hashtag="#champearthmotors"
                    style={{ marginRight: 5 }}
                >
                    <FacebookIcon size={40} round />
                </FacebookShareButton>
                <TwitterShareButton
                    url={`${window.location.href}`}
                    quote={'แชร์ Link!'}
                    hashtag="#champearthmotors"
                    style={{ marginRight: 5 }}
                >
                    <TwitterIcon size={40} round />
                </TwitterShareButton>
                <LineShareButton
                    url={`${window.location.href}`}
                    quote={'แชร์ Link!'}
                    hashtag="#champearthmotors"
                    style={{ marginRight: 5 }}
                >
                    <LineIcon size={40} round />
                </LineShareButton>
                <EmailShareButton
                    url={`${window.location.href}`}
                    quote={'แชร์ Link!'}
                    hashtag="#champearthmotors"
                    style={{ marginRight: 5 }}
                >
                    <EmailIcon size={40} round />
                </EmailShareButton>
            </>
        )
    }
}
