import React, { Component } from 'react'
import Helper from '../../Helper';
import PageHeader from '../../Include/PageHeader';
import Cube from '../../Animation/Cube';
import axios from 'axios';
import Loading from '../../component/Loading';
import NumberFormatInput from '../../component/NumberFormatInput';
import FormFileInput from '../../component/FormFileInput';
import FileInputMutiple from '../../component/FileInputMutiple';
import Swal from 'sweetalert2';
import moment from 'moment';
import { storage } from '../../firebase';
import { navigate } from '@reach/router';

const uploadImagePath = 'saleRequest/'
export default class SaleCarRequest extends Component {

    constructor() {
        super();
        let years = [];
        let now = new Date();
        let toYear = now.getFullYear() + 1
        for (let i = toYear; i >= 1990; i--) {
            years.push(i)
        }
        this.state = {
            is_loading: true,
            options: false,
            years: years,
            fileImageBooks: [],
            fileMoreImages: [],
        }

        this.$fileMoreImages = React.createRef()
        this.$fileImageBooks = React.createRef()
        this.$fileImageCar = React.createRef()
        this.$fileImage1stPageBook = React.createRef()
        this.$fileImageMile = React.createRef()
    }

    async fetch() {
        if (this.state.options) { return; }
        let res = await axios.get(`${window.api_host}/web/options`);
        // console.log(res)
        let options = false;
        if (res.data.success) {
            options = res.data.options;
        }
        this.setState({
            options: options,
            is_loading: false,
        })
    }

    componentDidMount() {
        this.loginCheck();
    }

    loginCheck() {
        let check = Helper.getCookie('saleCarUser') ? JSON.parse(Helper.getCookie('saleCarUser')) : false;
        if (!check || !check.phoneNumber) {
            window.location.href = `${window.location.origin}/sale/car/login`
            return;
        }
        this.fetch();
    }

    onSubmit(data) {
        let uploadimages = [];
        if (this.$fileImageCar
            && this.$fileImageCar[0]
            && this.$fileImageCar[0].files
            && this.$fileImageCar[0].files[0]) {
            uploadimages.push({ file: this.$fileImageCar, name: 'fileImageCar' })
        }
        if (this.$fileImageMile
            && this.$fileImageMile[0]
            && this.$fileImageMile[0].files
            && this.$fileImageMile[0].files[0]) {
            uploadimages.push({ file: this.$fileImageMile, name: 'fileImageMile' })
        }
        if (this.$fileImage1stPageBook
            && this.$fileImage1stPageBook[0]
            && this.$fileImage1stPageBook[0].files
            && this.$fileImage1stPageBook[0].files[0]) {
            uploadimages.push({ file: this.$fileImage1stPageBook, name: 'fileImage1stPageBook' })
        }
        let fileImageBooks = this.$fileImageBooks.current.getUploadImages();
        let fileMoreImages = this.$fileMoreImages.current.getUploadImages();

        if (uploadimages.length < 3
            || fileImageBooks.length == 0
            || fileMoreImages.length == 0) {
            Swal.fire({
                title: 'ไม่สามารถทำรายการได้!',
                text: 'กรุณาอัพโหลดรูปภาพ',
                icon: 'warning',
                confirmButtonText: 'OK!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-light'
                },
                buttonsStyling: false,
                reverseButtons: true
            })
            return;
        }

        Swal.fire({
            title: 'กำลังอัพโหลดรูปภาพ...',
            html: '<b></b>',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })
        this.uploadFile(uploadimages, 0, data)
    }


    uploadFile(uploadimages, index, data) {
        if (uploadimages.length == 0
            && this.props.mode === 'edit') {
            this.update(data);
            return;
        }
        let nextIndex = index + 1
        Helper.uploadFile(uploadimages[index].file, uploadImagePath, {
            resize: true,
            maxWidth: 1300,
            maxHeight: 1300,
            onSuccess: async (url) => {
                let momentDate = moment()
                let transformDate = momentDate.format('YYYY-MM-DD');
                data[uploadimages[index].name] = { ...url, created: transformDate }
                if ((nextIndex) < uploadimages.length) {
                    this.uploadFile(uploadimages, nextIndex, data)
                } else {
                    this.prepareUploadFileImageBooks(data);
                }
            },
            onError: async (obj) => {
                Swal.fire({
                    // title: 'Warning!',
                    text: obj.message,
                    icon: 'error',
                    confirmButtonText: 'OK!',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-light'
                    },
                    buttonsStyling: false,
                    reverseButtons: true
                })

            }
        })
    }

    async prepareUploadFileImageBooks(data) {
        let uploadImage = this.$fileImageBooks.current.getUploadImages();
        Swal.fire({
            title: 'กำลังอัพโหลดรูปภาพ...',
            html: '<b>กำลังอัพโหลดเอกสารเล่มรถ</b>',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading();
            }
        })
        this.uploadFileImageBooks(uploadImage, 0, data);
    }

    async uploadFileImageBooks(file_stack, upload_index, data) {
        let file = file_stack[upload_index];
        // console.log(this.state.file_stack)
        Helper.uploadFileNoNode(file, uploadImagePath, {
            resize: true,
            maxWidth: 1300,
            maxHeight: 1300,
            onSuccess: async (url) => {
                let uploaded_images = [...this.state.fileImageBooks];
                uploaded_images.push({
                    ...url
                })
                this.state.fileImageBooks = uploaded_images;
                upload_index++;
                if (upload_index >= file_stack.length) {
                    this.prepareUploadFileImageMoreCars(data);
                    return;
                }
                if (upload_index < file_stack.length) {
                    this.uploadFileImageBooks(file_stack, upload_index, data,)
                }


            },
            onError: async (obj) => {
                // console.log(error);
                Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
                for (const uploaded_image of this.state.fileImageBooks) {
                    await Helper.removeFile(uploaded_image.storagePath)
                }
                this.state.fileImageBooks = [];
                this.submit.removeClass('loading');
                return;

            }
        })
    }
    async prepareUploadFileImageMoreCars(data) {
        let uploadImage = this.$fileMoreImages.current.getUploadImages();
        Swal.fire({
            title: 'กำลังอัพโหลดรูปภาพ...',
            html: '<b>กำลังอัพโหลดรูปเพิ่มเติม</b>',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading();
            }
        })
        this.uploadFileImageMoreCars(uploadImage, 0, data);
    }

    async uploadFileImageMoreCars(file_stack, upload_index, data) {
        let file = file_stack[upload_index];
        // console.log(this.state.file_stack)

        Helper.uploadFileNoNode(file, uploadImagePath, {
            resize: true,
            maxWidth: 1300,
            maxHeight: 2500,
            onSuccess: async (url) => {
                let uploaded_images = [...this.state.fileMoreImages];
                uploaded_images.push({ ...url })
                this.state.fileMoreImages = uploaded_images;
                upload_index++;
                if (upload_index >= file_stack.length) {
                    this.create(data);
                    return;
                }
                if (upload_index < file_stack.length) {
                    this.uploadFileImageMoreCars(file_stack, upload_index, data,)
                }

            },
            onError: async (obj) => {
                // console.log(error);
                Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
                for (const uploaded_image of this.state.fileImageBooks) {
                    await Helper.removeFile(uploaded_image.storagePath)
                }
                for (const uploaded_image of this.state.fileMoreImages) {
                    await Helper.removeFile(uploaded_image.storagePath)
                }
                this.state.fileImageBooks = [];
                this.state.fileMoreImages = [];
                this.submit.removeClass('loading');
                return;

            }
        })
    }

    async create(data) {
        Swal.fire({
            title: 'กำลังเพิ่มข้อมูลเข้าสู่ระบบ',
            html: '<b></b>',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })
        data.fileImageBooks = this.state.fileImageBooks;
        data.moreCarImages = this.state.fileMoreImages;
        let res = await axios.post(`${window.api_host}/sale/request`, data);
        if (res.data.success) {
            Swal.fire({
                title: 'สำเร็จ!',
                text: `ทำการยื่นเสนอขายรถและประเมินราคาเรียบร้อยแล้ว`,
                icon: 'success',
                confirmButtonText: 'OK!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-light'
                },
                buttonsStyling: false,
                reverseButtons: true
            });
            Helper.setCookie("saleCarUser", null)
            navigate('/');
            return;
        }

        Swal.fire({
            title: 'ไม่สำเร็จ!',
            text: `ไม่สามารถยื่นเสนอขายรถและประเมินราคาได้กรุณาลองใหม่อีกครั้ง`,
            icon: 'error',
            confirmButtonText: 'OK!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light'
            },
            buttonsStyling: false,
            reverseButtons: true
        });


    }



    render() {
        return (
            <>
                <PageHeader
                    title={"ขายรถและประเมินราคา"}
                />
                <div className='new-cars'>
                    <form
                        className='container custom-input'
                        style={{ position: 'relative', zIndex: 10 }}
                        ref={(ref) => {
                            this.$form = window.$(ref);
                            this.$submit = window.$(ref).find('button[type="submit"]')
                            window.$(ref).off().on('submit', (e) => {
                                e.preventDefault();
                                let data = Helper.getFormInputObject(this.$form);
                                this.onSubmit(data);
                            });
                        }}
                    >
                        {
                            this.state.is_loading ?
                                <div style={{ paddingTop: 100, paddingBottom: 120 }}><Loading /></div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.options ?
                                <>
                                    <div className='row'>
                                        <div className="col-12 col-sm-3 mb-3">
                                            <label>ชื่อ</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="name"
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-3 mb-3">
                                            <label>เบอร์โทรศัพท์</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="phoneNumber"
                                                required={true}
                                                maxLength={10}
                                                onChange={(e) => {
                                                    let phoneNumber = 0;
                                                    if (!isNaN(e.target.value)) {
                                                        phoneNumber = e.target.value;
                                                    } else {
                                                        phoneNumber = this.state.phoneNumber;
                                                        window.$(`input[name="phoneNumber"]`).val(phoneNumber)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <CarBrandSelector
                                        options={this.state.options}
                                        qs={{}}
                                    />
                                    <div className='row'>
                                        <div className="col-12 col-sm-6 col-md-3 mb-3">
                                            <label htmlFor="year">ปี</label>
                                            <select
                                                ref={(ref) => {
                                                    window.$(ref).select2({ placeholder: 'ปี' });
                                                    if (this.state.data && this.state.data.year) {
                                                        window.$('#year').val(this.state.data.year).trigger('change')
                                                    } else {
                                                        window.$(ref).val(null).trigger('change');
                                                    }
                                                }}
                                                className="form-select"
                                                id="year"
                                                name="year"
                                                required={true}
                                                autoComplete={"off"}
                                            >
                                                {
                                                    this.state.years.map((year, i) => {
                                                        return (
                                                            <option value={year} key={i}>{year}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-3 mb-3">
                                            <label>เลขไมล์</label>
                                            <NumberFormatInput type="number" className='form-control' name="mile" />
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-3 mb-3">
                                            <label>สี</label>
                                            <input type="text" className='form-control' name="color" />
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-3 mb-3">
                                            <label>ราคาที่อยากได้</label>
                                            <NumberFormatInput type="text" className='form-control' name="neededPrice" />
                                        </div>
                                        <div className="col-12 col-sm-6 mb-3">
                                            <label>รายละเอียดรถ</label>
                                            <textarea className='form-control' rows={3} name="carDescription"></textarea>
                                        </div>
                                        <div className="col-12 col-sm-6 mb-3">
                                            <label>หมายเหตุ</label>
                                            <textarea className='form-control' rows={3} name="note"></textarea>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <FormFileInput
                                                ref={(ref) => {
                                                    if (ref && ref.$file) {
                                                        this.$fileImageCar = ref.$file;
                                                    }
                                                }}
                                                title={"รูปรถ"}
                                                is_required={true}
                                                fileName={"fileImageCar"}
                                                imagePath={this.state.data && this.state.data.fileImageCar ? this.state.data.fileImageCar.imagePath : ''}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormFileInput
                                                ref={(ref) => {
                                                    if (ref && ref.$file) {
                                                        this.$fileImage1stPageBook = ref.$file;
                                                    }
                                                }}
                                                title={"รูปหน้าเล่มทะเบียนรถหน้าแรก"}
                                                is_required={true}
                                                fileName={"fileImage1stPageBook"}
                                                imagePath={this.state.data && this.state.data.fileImage1stPageBook ? this.state.data.fileImage1stPageBook.imagePath : ''}

                                            />

                                        </div>
                                        <div className='col-md-4'>
                                            <FormFileInput
                                                ref={(ref) => {
                                                    if (ref && ref.$file) {
                                                        this.$fileImageMile = ref.$file;
                                                    }
                                                }}
                                                title={"รูปเลขไมล์"}
                                                is_required={true}
                                                fileName={"fileImageMile"}
                                                imagePath={this.state.data && this.state.data.fileImageMile ? this.state.data.fileImageMile.imagePath : ''}
                                            />
                                        </div>
                                    </div>
                                    <hr className='hr-color' />
                                    <div><label className='mb-0' style={{ fontWeight: 'bold' }} htmlFor="fileMoreImages">รูปรถเพิ่มเติม</label></div>
                                    <div><small style={{ fontWeight: 'bold' }}> หมายเหตุ : </small><small> ให้อัพโหลดรูปภาพรวมของ ภายนอก, ภายใน</small></div>
                                    <div className='row'>
                                        <FileInputMutiple
                                            ref={this.$fileMoreImages}
                                            moreImages={this.state.data && this.state.data.moreImages ? this.state.data.moreImages : []}
                                            imageHeight={150}
                                            inputName="fileMoreImages"
                                        />
                                    </div>
                                    <hr className='hr-color' />
                                    <label style={{ fontWeight: 'bold' }} htmlFor="fileImageBooks">เอกสารเล่มรถ</label>
                                    <div className='row'>
                                        <FileInputMutiple
                                            ref={this.$fileImageBooks}
                                            moreImages={this.state.data && this.state.data.fileImageBooks ? this.state.data.fileImageBooks : []}
                                            imageHeight={150}
                                            inputName="fileImageBooks"
                                        />
                                    </div>
                                    <hr className='hr-color' />
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <button className='button-53 mt-5' type="submit">ยืนยัน</button>
                                    </div>
                                </>
                                : null
                        }
                    </form>
                    <Cube />
                </div>
            </>
        )
    }
}
class CarBrandSelector extends Component {
    getModelSelect(value) {
        let data = []
        if (!value && this.$model) {
            this.$model.find('option').remove()
            this.$model.select2({
                allowClear: true,
                placeholder: "รุ่น",
                data: null
            })
            this.$model.empty().val(null).trigger("change");
            return;
        }
        let ref = window.$('[name="model"]');
        ref.find('option').remove()
        let brand = this.props.options.brand.filter((e) => { return e.name == value })[0];
        if (brand) {
            let models = this.props.options.model.filter((e) => { return e.brandUid == brand.id });
            for (const model of models) {
                data.push({ text: model.name, id: model.name })
            }
        }
        if (this.$model) {
            this.$model.select2({
                allowClear: true,
                placeholder: "รุ่น",
                data: data
            })
            this.$model.val(null).trigger('change');
            if (this.props.qs.model) {
                this.$model.val(this.props.qs.model).trigger('change');
            }
        }
    }
    getMinorSelect(value) {
        let data = []
        if (!value && this.$minor) {
            this.$minor.find('option').remove()
            this.$minor.select2({
                allowClear: true,
                placeholder: "โฉมรถ",
                data: null
            })
            this.$minor.empty().val(null).trigger("change");
            return;
        }
        let ref = window.$('[name="minor"]');
        ref.find('option').remove()
        let model = this.props.options.model.filter((e) => { return e.name == value })[0];
        if (model) {
            let minors = this.props.options.minor.filter((e) => { return e.modelUid == model.id });
            for (const minor of minors) {
                data.push({ text: minor.name, id: minor.name })
            }
        }
        if (this.$minor) {
            this.$minor.select2({
                allowClear: true,
                placeholder: "โฉมรถ",
                data: data
            })
            this.$minor.val(null).trigger('change');
            if (this.props.options.minor) {
                this.$minor.val(this.props.options.minor).trigger('change');
            }
        }
    }

    getSubModelSelect(value) {
        let data = []
        if (!value && this.$submodel) {
            this.$submodel.find('option').remove()
            this.$submodel.select2({
                allowClear: true,
                placeholder: "รุ่นย่อย",
                data: null
            })
            this.$submodel.empty().val(null).trigger("change");
            return;
        }
        let ref = window.$('[name="submodel"]');
        ref.find('option').remove()
        let minor = this.props.options.minor.filter((e) => { return e.name == value })[0];
        if (minor) {
            let submodels = this.props.options.submodel.filter((e) => { return e.minorUid == minor.id });
            for (const model of submodels) {
                data.push({ text: model.name, id: model.name })
            }
        }
        if (this.$submodel) {
            this.$submodel.select2({
                allowClear: true,
                placeholder: "รุ่นย่อย",
                data: data
            })
            this.$submodel.val(null).trigger('change');
            if (this.props.qs.submodel) {
                this.$submodel.val(this.props.qs.submodel).trigger('change');
            }
        }
    }
    render() {
        return (
            <div className='row'>
                <div className='col-12 col-md-6 col-lg-3  mb-3'>
                    <label>ยี่ห้อ</label>
                    <select
                        ref={(ref) => {
                            this.$brand = window.$(ref)
                            this.$brand.select2({
                                allowClear: true,
                                placeholder: "ยี่ห้อ",
                            })
                            if (this.props.qs.brand && this.props && this.props.options) {
                                this.$brand.val(this.props.qs.brand).trigger('change');
                                // this.getModelSelect(this.props.qs.brand);
                            } else {
                                this.$brand.val(null).trigger('change');
                            }
                            this.$brand.on('change', (e) => {
                                this.getModelSelect(e.target.value)
                            })

                            this.$brand.on('select2:clear', () => {
                                this.getModelSelect()
                                this.getMinorSelect()
                                this.getSubModelSelect()
                            })
                        }}
                        className="form-select"
                        id="brand"
                        name="brand"
                        required={false}
                        autoComplete={"off"}
                    >
                        {
                            this.props.options.brand.map((brand, i) => {
                                return (
                                    <option value={brand.name} key={i}>{brand.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='col-12 col-md-6 col-lg-3  mb-3'>
                    <label>รุ่น</label>
                    <select
                        ref={(ref) => {
                            this.$model = window.$(ref);
                            this.$model.select2({
                                allowClear: true,
                                placeholder: "รุ่น",
                            })
                            this.$model.val(null).trigger('change');
                            if (this.props.qs.brand) {
                                this.getModelSelect(this.props.qs.brand);
                            }
                            this.$model.on('change', (e) => {
                                this.getMinorSelect(e.target.value)
                            })
                            if (this.props.qs.model && this.props && this.props.options) {
                                this.$model.val(this.props.qs.model).trigger('change');
                            }

                            this.$model.on('select2:clear', () => {
                                this.getMinorSelect()
                                this.getSubModelSelect()
                            })
                        }}
                        className='form-select mb-3'
                        name="model"
                    >
                    </select>
                </div>
                <div className='col-12 col-md-6 col-lg-3  mb-3'>
                    <label>โฉม</label>
                    <select
                        ref={(ref) => {
                            this.$minor = window.$(ref);
                            this.$minor.select2({
                                allowClear: true,
                                placeholder: "โฉมรถ",
                            })
                            this.$minor.val(null).trigger('change');
                            if (this.props.qs.model) {
                                this.getMinorSelect(this.props.qs.model);
                            }
                            this.$minor.on('change', (e) => {
                                this.getSubModelSelect(e.target.value)
                            })
                            if (this.props.qs.minor && this.props && this.props.options) {
                                this.$minor.val(this.props.qs.minor).trigger('change');
                            }
                            this.$minor.on('select2:clear', () => {
                                this.getSubModelSelect()
                            })
                        }}
                        className='form-select mb-3'
                        name="minor"
                    >
                    </select>
                </div>
                <div className='col-12 col-md-6 col-lg-3  mb-3'>
                    <label>รุ่นย่อย</label>
                    <select
                        ref={(ref) => {
                            this.$submodel = window.$(ref);
                            this.$submodel.select2({
                                allowClear: true,
                                placeholder: "รุ่นย่อย",
                            })
                            this.$submodel.val(null).trigger('change');
                            if (this.props.qs.minor) {
                                this.getSubModelSelect(this.props.qs.minor);
                            }
                            if (this.props.qs.submodel && this.props && this.props.options) {
                                this.$submodel.val(this.props.qs.submodel).trigger('change');
                            }

                        }}
                        className='form-select mb-3'
                        name="submodel"
                    >
                    </select>
                </div>
            </div>
        )
    }
}