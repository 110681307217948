import React, { Component } from 'react'
import PageHeader from '../../Include/PageHeader';
import Helper from '../../Helper';
import { navigate } from '@reach/router';
import axios from 'axios';
import Swal from 'sweetalert2';
export default class SaleCarOTP extends Component {
    constructor() {
        super();
        this.state = {
            otp: '',
            phoneNumber: Helper.getParameterByName('phoneNumber') || '',
        }
    }

    async sendOTP() {
        let res = await axios.post(`${window.api_host}/web/otp/send/${this.state.phoneNumber}`, { otpCode: this.state.otp });
        if (res.data.success) {
            Helper.setCookie('saleCarUser', JSON.stringify({ phoneNumber: this.state.phoneNumber }), 1)
            navigate('/sale/car')
        } else {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด!',
                text: res?.data?.message || `กรุณาลองใหม่อีกครั้ง`,
                icon: 'error',
                confirmButtonText: 'OK!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-light'
                },
                buttonsStyling: false,
                reverseButtons: true
            })
            return;
        }
    }
    render() {
        return (
            <section className='sale-car-form'>
                <PageHeader title={"ขายรถและประเมินราคา"} />
                <div className='container'>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.sendOTP()
                        }}
                        className='sale-car-box d-flex flex-column justify-content-center align-items-center'
                    >
                        <h2 style={{ fontWeight: 'bold', letterSpacing: 5 }}>{this.state.phoneNumber}</h2>
                        <h4 className='mb-3'>กรุณากรอกรหัสยืนยันตัวตน</h4>
                        <input name="otp" maxLength={6}
                            onChange={(e) => {
                                let otp = 0;
                                if (!isNaN(e.target.value)) {
                                    otp = e.target.value;
                                } else {
                                    otp = this.state.otp;
                                    window.$(`input[name="otp"]`).val(otp)
                                }

                                this.setState({
                                    otp: otp,
                                })
                            }}
                        />
                        <ResendButton phoneNumber={this.state.phoneNumber} />
                        <button className='button-53 mt-4' type="submit">ยืนยัน</button>
                    </form>
                </div>
            </section>
        )
    }
}
class ResendButton extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 0,
        }
    }
    componentDidMount() {
        let countDown = 60;
        let time = setInterval(() => {
            this.sendOtp.html("กดส่งใหม่ได้ใน " + countDown)
            countDown--;
            if (countDown < 0) {
                this.sendOtp.removeClass('loading')
                this.sendOtp.html('ส่งใหม่');
                clearInterval(time)
            }
        }, 1000)
    }

    async resend() {
        if (this.sendOtp.hasClass('loading')) { return; }
        this.sendOtp.addClass('loading')
        this.sendOtp.html('กำลังส่ง OTP');

        let res = await axios.post(`${window.api_host}/web/otp/request/${this.props.phoneNumber}`);
        let countDown = 10;
        let time = setInterval(() => {
            this.sendOtp.html("กดส่งใหม่ได้ใน " + countDown)
            countDown--;
            if (countDown < 0) {
                this.sendOtp.removeClass('loading')
                this.sendOtp.html('ส่งใหม่');
                clearInterval(time)
            }
        }, 1000)
    }

    render() {
        return (
            <div className='mb-0 d-flex justify-content-center align-items-center mt-3 loading'>
                <div className='link'
                    style={{ cursor: 'pointer' }}
                    ref={(ref) => { this.sendOtp = window.$(ref); }}
                    onClick={() => {
                        this.resend();
                    }}
                >
                    กำลังประมวลผล
                </div>
            </div>
        )
    }
}