import React from 'react';
import ReactDOM from 'react-dom';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from './firebase'
window.months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];
window.months_eng = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];

const Compress = require('compress.js').default;
const compress = new Compress();

const Helper = {
  setButtonLoading(node) {
    node.attr("data-kt-indicator", "on")
    node.attr("disabled", true)
  },
  setButtonLoaded(node) {
    node.attr("data-kt-indicator", "off")
    node.attr("disabled", false)
  },
  getFormInputObject(form) {
    let data = {};
    form.serializeArray().map((item) => {
      data[item.name] = item.value;
      return false;
    })
    return data;
  },
  reactToDom($dom, Component, props) {
    // console.log(typeof Component);
    if (typeof Component === 'function') {
      ReactDOM.render(React.createElement(Component, props, null), document.createElement('div'), function () {
        $dom.append(ReactDOM.findDOMNode(this));
      });
    }
    if (typeof Component === 'object') {
      ReactDOM.render(Component, document.createElement('div'), function () {
        $dom.append(ReactDOM.findDOMNode(this));
      });
    }
  },
  numberFormat(number, options) {
    if (!number) { return 0 }
    number = parseFloat(number);
    if (options && options.decimal) {
      number.toFixed(options.decimal)
    }
    if (isNaN(number)) {
      number = 0;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  objToGetParameter(obj) {
    let str = "";
    for (let key in obj) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + obj[key];
    }
    return str;
  },
  getQueryStringLinkFromObject(query_string, url) {
    var q = [];
    window.$.each(query_string, function (key, value) {
      q.push(key + '=' + encodeURIComponent(value));
    });
    var page_name = window.location.pathname;
    if (url) page_name = url;
    if (q.length) {
      page_name += '?' + q.join('&');
    }
    return page_name;
  },
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // (optional) keep case consistent
        // paramName = paramName.toLowerCase();
        // if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {

          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  },
  clearAllCookie() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  },
  setCookie(cname, cvalue, exdays) {

    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    // cvalue = window.escape(cvalue);
    cvalue = window.escape(cvalue);
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        // c = window.unescape(c);
        c = window.unescape(c);
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  getDate(_date, options) {
    let date = new Date(_date);
    let time = '';
    if (options && options.is_show_time) {
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hour < 10) {
        hour = '0' + date.getHours()
      }
      if (minute < 10) {
        minute = '0' + date.getMinutes()
      }
      if (second < 10) {
        second = '0' + date.getSeconds()
      }
      // time = ' - ' + hour + ':' + minute + ':' + second;
      time = ' - ' + hour + ':' + minute;
    }
    if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
      return "";
    }
    if (options && options.lang === 'en') {
      return date.getDate() + ' ' + window.months_eng[parseFloat(date.getMonth())] + ' ' + (date.getFullYear()) + time;
    }
    return date.getDate() + ' ' + window.months_th[parseFloat(date.getMonth())] + ' ' + (date.getFullYear() + 543) + time;
  },
  async removeFile(path) {
    const desertRef = ref(storage, path);
    let _response = {};
    deleteObject(desertRef).then(() => {
      _response.success = true;
    }).catch((error) => {
      _response.error = true;
      _response.error_data = error;
    });
    // console.log("removeFile response",_response)
    return _response;
  },
  async uploadFileAsync(node, path, options) {
    // let id = this.state.provider_id;
    let upload_btn = node.parent().find('.btn');
    if (upload_btn.hasClass("loading")) { return false }
    upload_btn.addClass('loading');
    let file = node[0].files[0];
    if (!file) {
      upload_btn.removeClass('loading')
      return { error: true, message: 'No file' };
    }
    console.log("file.type", file)
    if (file
      && file.type !== 'image/png'
      && file.type !== 'image/jpeg'
      && file.type !== 'image/jpg'
    ) {

      upload_btn.removeClass('loading')
      return { error: true, message: 'not image' };
    }

    const filename = Helper.setNewImageFileName(file);
    let reszieFile = file;
    if (options.resize) {
      reszieFile = await Helper.resizeImageFn(file, options)
    }
    console.log(path + filename)
    var uploadTask = await storage.ref().child(path + filename).put(reszieFile);
    return uploadTask;
  },
  async uploadFile(node, path, options) {
    // let id = this.state.provider_id;
    let upload_btn = node.parent().find('.btn');
    if (upload_btn.hasClass("loading")) { return false }
    upload_btn.addClass('loading');
    let file = node[0].files[0];
    // console.log("file.type", file)
    if (file
      && file.type !== 'image/png'
      && file.type !== 'image/jpeg'
      && file.type !== 'image/jpg'
    ) {
      if (options.onError) {
        options.onError({ error: true, message: "กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น" });
      }
      upload_btn.removeClass('loading')
      return false;
    }
    const filename = Helper.setNewImageFileName(file);
    let reszieFile = file;
    if (options.resize) {
      reszieFile = await Helper.resizeImageFn(file, options)
    }
    // console.log(path + filename)
    const storageRef = ref(storage, path + filename);
    var uploadTask = uploadBytesResumable(storageRef, reszieFile)


    uploadTask.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // console.log("snapshot", snapshot);
    }, (error) => {
      // console.log(error);
      upload_btn.removeClass('loading');
      if (options.onError) {
        options.onError({ message: 'อัพโหลดรูปไม่สำเร็จ' });
        upload_btn.removeClass('loading')
      }
    }, async () => {
      let url = {};
      url.storePath = path + filename;
      url.imagePath = await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        return downloadURL;
      });
      // url.imagePath = await storage.ref(path + filename).getDownloadURL();
      // console.log("url", url)
      if (options.onSuccess) {
        options.onSuccess(url);
        upload_btn.removeClass('loading')
      }
    });

  },
  async uploadFileNoNode(file, path, options) {
    // console.log("file.type", file)
    if (file
      && file.type !== 'image/png'
      && file.type !== 'image/jpeg'
      && file.type !== 'image/jpg'
    ) {
      if (options.onError) {
        options.onError({ error: true, message: "กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น" });
      }
      return false;
    }
    const filename = Helper.setNewImageFileName(file);
    let reszieFile = file;
    if (options.resize) {
      reszieFile = await Helper.resizeImageFn(file, options)
    }
    // console.log(path + filename)
    const storageRef = ref(storage, path + filename);
    var uploadTask = uploadBytesResumable(storageRef, reszieFile)


    uploadTask.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // console.log("snapshot", snapshot);
    }, (error) => {
      // console.log(error);
      if (options.onError) {
        options.onError({ message: 'อัพโหลดรูปไม่สำเร็จ' });
      }
    }, async () => {
      let url = {};
      url.storePath = path + filename;
      url.imagePath = await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        return downloadURL;
      });
      // url.imagePath = await storage.ref(path + filename).getDownloadURL();
      // console.log("url", url)
      if (options.onSuccess) {
        options.onSuccess(url);
      }
    });

  },
  readURL(input) {
    let show_image_Ref = input.parent().find('#show_image');
    let checktype = false;
    if (input[0].files
      && input[0].files[0]
      && (input[0].files[0].type === 'image/png'
        || input[0].files[0].type === 'image/jpeg'
        || input[0].files[0].type === 'image/jpg'
      )) {
      checktype = true;
    }
    // console.log(checktype);
    if (input[0].files && input[0].files[0] && checktype) {

      var reader = new FileReader();
      reader.onload = (e) => {
        input.parent().find('.button').text('เปลี่ยนรูป');
        if (show_image_Ref) {
          show_image_Ref.html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
          show_image_Ref.html('<img id="blah" src="' + e.target.result + '" alt="your image" style="max-width: 100%" />');
        }
      };

      reader.readAsDataURL(input[0].files[0]);
      input.parent().find('.btn').html("เปลี่ยนรูป")
      return { success: true };
    } else {
      // show_image_Ref.html('');
      input.val('');
      return { error: true, message: "กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น" };
    }
  },
  setNewImageFileName(file) {
    let date = new Date();
    let file_type = file.name.substr(file.name.indexOf('.'), file.name.indexOf('.') + 3);
    let file_name = file.name.substr(0, file.name.indexOf('.'));
    return file_name + Date.parse(date) + file_type;
  },
  async resizeImageFn(file, options) {
    const resizedImage = await compress.compress([file], {
      size: 20, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: options && options.maxWidth ? options.maxWidth : 500, // the max width of the output image, defaults to 1920px
      maxHeight: options && options.maxHeight ? options.maxHeight : 500, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    // return img;
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
  },
};

export default Helper;