import React, { Component } from 'react'

export default class FABs extends Component {
    render() {
        return (
            <div className="fab-wrapper custom">
                <input
                    type="checkbox"
                    name="fab-toggle"
                    className="fab-toggle"
                // onChange={(e) => {
                //     let swingNode = window.$('.fab-button');
                //     if (window.$(e.target).is(':checked')) {
                //         swingNode.addClass('animation')
                //         swingNode.addClass('swing')
                //     } else {
                //         swingNode.removeClass('animation')
                //         swingNode.removeClass('swing')
                //     }
                // }}
                />
                {/* <a className="fab-button" href="#!"><i className="fa fa-plus"></i></a> */}
                <a className="fab-button animation swingSlow infinite" href="#!">
                    <img src={`${window.origin}/assets/images/sticker_copy.png`} className="image" alt={`reviewer`} />
                </a>
                <div className="fab-actions">
                    <a href="https://www.facebook.com/ChampEarthMotors" target={"_blank"} title="Facebook"><i className="fa-brands fa-facebook-f"></i></a>
                    <a href="https://lin.ee/2BwZQnijS" target={"_blank"} title="Line"><i className="fa-brands fa-line"></i></a>
                    <a href="https://www.youtube.com/@champearthmotors3741/videos" target={"_blank"} title="Youtube"><i className="fa-brands fa-youtube"></i></a>
                    <a href="tel:0991464646" target={"_blank"} title="Contact Phone"><i className="fas fa-phone"></i></a>
                </div>
            </div>
        )
    }
}
