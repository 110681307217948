import React from 'react';

export default class Loading extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <span className="spinner-border spinner-border- align-middle me-3"></span> <h3 className='mb-0' style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 30, textTransform: 'uppercase' }}>Loading...</h3>
            </div>
        )
    }
}