import React from 'react';
import NewCars from './include/NewCars';
import OursCars from './include/OursCars';
import Slide from './include/Slide';
import UsersReview from './include/UsersReview';
import Banner from './include/Banner';
import AboutUs from './include/AboutUs';
import Blogs from './include/Blogs';
import Loading from '../../component/Loading';
import axios from 'axios';

export default class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: false,
        }
        this.NewCars = React.createRef();
        this.OursCars = React.createRef();
        this.UsersReview = React.createRef();
        this.Carousel = React.createRef();
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.get(`${window.api_host}/web/get-cars`);
        if (res.data.success) {
            if (this.Carousel?.current?.updateCarousel) {
                this.Carousel.current.updateCarousel(res.data.carousels)
            }
            if (this.NewCars?.current?.updateCars) {
                this.NewCars.current.updateCars(res.data.newCars)
            }
            if (this.OursCars?.current?.updateCars) {
                this.OursCars.current.updateCars(res.data.ourCars)
            }
            if (this.UsersReview?.current?.updateReviews) {
                this.UsersReview.current.updateReviews(res.data.reviews)
            }
        }

    }

    render() {
        if (this.state.is_loading) {
            return (
                <>
                    <div style={{ padding: 100 }}>
                        <Loading />
                    </div>
                </>
            )
        }
        return (
            <>
                <Slide ref={this.Carousel} />
                <Banner />
                <AboutUs />
                <NewCars ref={this.NewCars} />
                <OursCars ref={this.OursCars} />
                <UsersReview ref={this.UsersReview} />
                <Blogs />
            </>
        )
    }
}