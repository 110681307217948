import React, { Component } from 'react'
import Helper from '../../Helper';
import PageHeader from '../../Include/PageHeader';
import axios from 'axios';
import Loading from '../../component/Loading';
import { Link } from '@reach/router';
import Cube from '../../Animation/Cube';

const default_limit = 12;
export default class BlogsList extends Component {
    constructor() {
        super();

        this.state = {
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            is_loading: true,
            list: [],
            qs: {
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            },
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.get(`${window.api_host}/blogs`);
        let list = [...this.state.list];
        let pagination = false;
        if (res.data.success) {
            list = list.concat(res.data.data);
            pagination = res.data.pagination;
        }
        this.setState({
            is_loading: false,
            list: list,
            pagination: pagination,
        }, () => {
            this.viewMore?.html("ดูเพิ่ม")
            this.viewMore?.removeClass("loading")
        })
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <PageHeader title={"Blogs"} />
                <section className='ourscars pt-0 pb-0'>
                    <div className='new-cars'>
                        <div className='container mt-3' style={{ position: 'relative', zIndex: 3 }}>
                            {
                                this.state.is_loading ?
                                    <div style={{ paddingTop: 100, paddingBottom: 120 }}><Loading /></div>
                                    : null
                            }
                            {
                                !this.state.is_loading
                                    && this.state.list.length > 0 ?
                                    <div className='row' id="car-list">
                                        {
                                            this.state.list.map((item, key) => {
                                                return (
                                                    <React.Fragment key={key}>
                                                        <div className='pl-3 pr-3 mb-3 mt-3'>
                                                            <BlogsCard data={item} />
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading
                                    && this.state.pagination
                                    && this.state.pagination.now_page < this.state.pagination.pages ?
                                    <div className='mt-5 d-flex justify-content-center align-items-center'>
                                        {/* <Link to="/" className='button-53'>ดูทั้งหมด</Link> */}
                                        <button
                                            ref={(ref) => {
                                                this.viewMore = window.$(ref);
                                                this.viewMore.off().on('click', () => {
                                                    if (this.viewMore.hasClass('loading')) return;
                                                    this.viewMore.addClass('loading');
                                                    this.viewMore.html("กำลังโหลดข้อมูล...")
                                                    let qs = this.state.qs;
                                                    qs.page = this.state.pagination.now_page + 1;
                                                    this.setState({ qs: qs }, () => {
                                                        this.fetch();
                                                    })
                                                })
                                            }}
                                            className='button-53'
                                        >ดูเพิ่ม</button>

                                    </div>
                                    : null
                            }
                        </div>

                        <Cube />

                    </div>
                </section>
            </div>
        )
    }
}


export class BlogsCard extends React.Component {
    render() {
        let data = this.props.data;
        return (
            <div style={{ position: 'relative' }}>
                <Link to={`/blog/${data.uid}`} className='car-card'>

                    <div className='car-card-image' style={{ position: 'relative' }}>
                        {
                            data.fileImage?.imagePath ?
                                <img src={data.fileImage.imagePath} alt={"car"} />
                                : null
                        }
                    </div>
                    <div className='car-card-info'>
                        <h3>{data.title}</h3>
                        <p className='mb-0'>{Helper.getDate(data.created, { is_show_time: true })}</p>
                        <p className='mb-0 text-black read-more'>อ่านต่อ <i className="fa-solid fa-arrow-right"></i></p>
                    </div>
                </Link>
            </div>
        )
    }
}