import React, { Component } from 'react'
import { Link } from '@reach/router'

export default class Header extends Component {
    closeMenu() {
        let containerRef = window.$('.menu');
        containerRef.removeClass('open')
        this.$menuBtn.parent().removeClass('open')
    }

    renderShowBtnOnResponsive() {
        return (
            <div className='show-resp'>
                <div className='btn-toggle-menu-container '>
                    <button
                        onClick={() => {
                            let searchContainerRef = window.$('.search-cars-container');
                            let searchBtnToggleMenuContainerRef = window.$('.btn-toggle-menu-container');
                            if (searchContainerRef.hasClass('open')) {
                                searchContainerRef.removeClass('open')
                                searchBtnToggleMenuContainerRef.removeClass('open')
                            } else {
                                searchContainerRef.addClass('open')
                                searchBtnToggleMenuContainerRef.addClass('open')
                            }
                        }}
                        className='car-card btn-toggle-menu '
                    >
                        {/* <i className="las la-angle-right closedIcon"></i>
                    <i className="las la-angle-left openedIcon"></i> */}
                        <i className="las la-search closedIcon"></i>
                        <i className="las la-search openedIcon"></i>
                        {/* <i className="fas fa-filter filtered"></i> */}
                    </button>
                </div>
            </div>
        )
    }
    render() {
        return (
            <header>
                <div className='container-fluid'>
                    <div className='d-flex flex-row' style={{ position: 'relative' }}>
                        {this.renderShowBtnOnResponsive()}
                        <div>
                            <Link to={"/"}>
                                <div className='logo'>
                                    <img src={`${window.origin}/assets/logo/logo-text.png`} />
                                </div>
                            </Link>
                        </div>
                        <ul className='menu'>
                            <li>
                                <Link to={"/"} onClick={() => {
                                    this.closeMenu()
                                }}>หน้าแรก</Link>
                            </li>
                            <li>
                                <Link to={"/cars"} onClick={() => {
                                    this.closeMenu()
                                }}>รถทั้งหมดของเรา</Link>
                            </li>
                            <li>
                                <Link to={"/blogs"} onClick={() => {
                                    this.closeMenu()
                                }}>บทความ</Link>
                            </li>
                            <li>
                                <Link to={"/sale/car"} onClick={() => {
                                    this.closeMenu()
                                }}>ให้ทางเราช่วยประเมินราคารถ</Link>
                            </li>
                            <li>
                                <Link to={"/reviews"} onClick={() => {
                                    this.closeMenu()
                                }}>รีวิว</Link>
                            </li>
                            <li>
                                <Link to={"/about"} onClick={() => {
                                    this.closeMenu()
                                }}>เกี่ยวกับเรา</Link>
                            </li>
                            {/* <li>
                                <Link to={"/contact"} onClick={() => {
                                    this.closeMenu()
                                }}>ติดต่อเรา</Link>
                            </li> */}
                        </ul>
                    </div>
                    <div className='show-mobile btn-menu-container'>
                        <div
                            ref={(ref) => {
                                this.$menuBtn = window.$(ref)
                            }}
                            className='btn-menu'
                            onClick={() => {

                                let containerRef = window.$('.menu');
                                if (containerRef.hasClass('open')) {
                                    containerRef.removeClass('open')
                                    this.$menuBtn.parent().removeClass('open')
                                } else {
                                    containerRef.addClass('open')
                                    this.$menuBtn.parent().addClass('open')
                                }
                            }}
                        >
                            <i className='las la-bars closedIcon' />
                            <i className='las la-times openedIcon' />
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
