import React, { Component } from 'react'
import FireFly from '../../../Animation/FireFly'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from '../../../component/Loading';


export default class Slide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    updateCarousel(list) {
        this.setState({
            is_loading: false,
            list: list
        })
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            dots: false,
            arrows: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            pauseOnDotsHover: false,
            fade: true,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 5000,
            cssEase: "linear",
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            initialSlide: 0,
            // afterChange: (current, next) => {
            //     window.$('#slideDescription').fadeOut("slow");
            //     setTimeout(() => {
            //         window.$('#slideDescription').html(`${this.state.list[current].description}`).fadeIn()
            //     }, 500)
            // },
            beforeChange: (current) => {
                window.$('#preview').fadeOut("slow");
                setTimeout(() => {
                    let customStyle = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', lineHeight: 0.5 };
                    if (this.state.list[current].isOneLine == `true`) {
                        customStyle = { display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }
                    }
                    window.$('#preview').css({ ...customStyle }).fadeIn("slow")
                    let titleNode = window.$('#title')
                    if (this.state.list[current].title) {
                        titleNode.html(this.state.list[current].title)
                    } else {
                        titleNode.html('')
                    }
                    let subtitleNode = window.$('#subtitle')
                    if (this.state.list[current].subtitle) {
                        subtitleNode.html(this.state.list[current].subtitle)
                    } else {
                        subtitleNode.html('')
                    }
                }, 1000)
                window.$('#slideDescription').fadeOut("slow");
                setTimeout(() => {
                    window.$('#slideDescription').html(`${this.state.list[current].description}`).fadeIn("slow")
                }, 1200)

            }
        };
        if (this.state.is_loading) {
            return (
                <section className='cover'>
                    <div className='container' style={{ position: 'relative' }}>
                        <div className='row'>
                            <div className='col-12 col-md-7 slide-info'>

                                <h1>champ earth <small style={{ color: '#E36C78' }}>motors</small></h1>
                                <p className='description' id="slideDescription" style={{ whiteSpace: 'pre-wrap' }}>
                                    ยินดีต้อนรับสู่ CHAMP EARTH MOTORS <br />
                                    กำลังโหลดข้อมูล...
                                </p>
                            </div>
                            <div className='col-12 col-md-5'>
                                <div className='slide-pos' style={{ overflow: 'hidden', border: '2px solid #ccc' }}>
                                    <div>
                                        <div className="slide-block-sm" style={{ alignItems: 'center', backgroundColor: '#f4f4f4' }}>
                                            <Loading />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        let customStyle = {};
        if (this.state.list[0]?.isOneLine == `true`) {
            customStyle = { display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }
        }
        return (
            <section className='cover'>
                <div className='container' style={{ position: 'relative' }}>
                    <div className='row'>
                        <div className='col-12 col-md-7 slide-info'>
                            {
                                this.state.list
                                    && this.state.list.length > 0 ?
                                    <>
                                        <div id="preview" style={{ ...customStyle }}>
                                            <h1 id="title" style={{ marginBottom: 0, marginRight: 10, lineHeight: 1 }}>{this.state.list[0].title}</h1>
                                            <h1 style={{ marginBottom: 0, lineHeight: 1 }}>
                                                <small id="subtitle" style={{ color: '#E36C78' }}>{this.state.list[0].subtitle}</small>
                                            </h1>
                                        </div>
                                        <p className='description mt-4' id="slideDescription" style={{ whiteSpace: 'pre-wrap' }}>
                                            {
                                                this.state.list[0].description

                                            }
                                        </p>
                                    </>
                                    : null
                            }
                        </div>
                        <div className='col-12 col-md-5'>
                            <div className='slide-pos'>
                                <Slider
                                    {...settings}
                                >
                                    {
                                        this.state.list ?
                                            this.state.list.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="slide-block-sm">
                                                            <img src={`${item.fileImage.imagePath}`} style={{ maxWidth: '100%' }} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }


                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )

        return (
            <div style={{ position: 'relative' }}>
                <FireFly />
                <Slider ref={c => (this.slider = c)} {...settings}>
                    <div key={1}>
                        <div className="slide-block">
                            <img src={`${window.origin}/assets/img/slide/1.jpg`} style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                    <div key={2} className="slide-block">
                        <div className="slide-block">
                            <img src={`${window.origin}/assets/img/slide/2.jpg`} style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                    <div key={3} className="slide-block">
                        <div className="slide-block">
                            <img src={`${window.origin}/assets/img/slide/3.jpg`} style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                    <div key={4} className="slide-block">
                        <div className="slide-block">
                            <img src={`${window.origin}/assets/img/slide/4.jpg`} style={{ maxWidth: '100%' }} />
                        </div>
                    </div>

                </Slider>
                {/* <div style={{ textAlign: "center", marginTop: 50 }}>
                    <button className="button" onClick={this.previous}>
                        Previous
                    </button>
                    <button className="button" onClick={this.next}>
                        Next
                    </button>
                </div> */}
            </div>
        );
    }
}
