import { Hidden } from '@mui/material'
import React, { Component } from 'react'

export default class VerticalPreviewImages extends Component {
    componentDidMount() {
        window.$('body, html').css({
            overflow: 'hidden',
        })
        setTimeout(() => {
            this.moveToImage();
        }, 200)
    }

    moveToImage() {
        console.log('moveToImage', window.$('#selected-img').offset())
        window.$('.preview-image').animate({
            scrollTop: window.$('#selected-img').offset().top - window.$('#show_picture').offset().top + window.$('#show_picture').scrollTop() - 15
        }, 500);
    }
    closePreview() {
        window.$('.preview-image').remove();
        window.$('body, html').css({
            overflow: 'auto',
        })
    }
    render() {
        let images = this.props.images || [];
        let selectedIndex = this.props.selectedIndex || 0
        // console.log(images)
        return (
            <div className='preview-image'>
                <div className='preview-backdrop'
                    ref={(ref) => {
                        window.$(ref).off().on('click', () => {
                            this.closePreview();
                        })
                    }}
                />
                <div className='remove-position'>
                    <button
                        type="button"
                        className='remove-button'
                        ref={(ref) => {
                            window.$(ref).off().on('click', () => {
                                this.closePreview();
                            })
                        }}
                    >
                        <i className='las la-times' />
                    </button>
                </div>
                <div id="show_picture">
                    {
                        images.length > 0 ?
                            images.map((image, i) => {
                                return (
                                    <div className={`preview-image-frame `} id={`${selectedIndex == i ? "selected-img" : ""}`} key={i}>
                                        <img src={image.imagePath} alt={'preview'} />
                                    </div>
                                )
                            })
                            : null
                    }
                </div>
            </div>
        )
    }
}
