import React, { Component } from 'react'

export default class PageHeader extends Component {
    render() {
        return (
            <section className='cover'>
                <div className='container' style={{ position: 'relative' }}>
                    <div className='row'>
                        <div className='col-12 slide-info page-inside'>
                            <h1>champ earth <small style={{ color: '#E36C78' }}>motors</small></h1>
                            {
                                this.props.title ?
                                    <>
                                        <h2 className='mb-4'>{this.props.title}</h2>
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
