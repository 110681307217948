import React, { Component } from 'react';
import { Link } from '@reach/router'
// import Helper from '../../../Helper';

export default class Banner extends Component {
    render() {
        return (
            <section className='banner-box'>
                <h2>champearth <small style={{ color: '#E36C78', fontWeight: 'bold' }}>motors</small></h2>
                <div className='container mt-5'>
                    <div className='row banner-list'>
                        <div className='col-12 col-md-4 mb-4 banner-item'>
                            <Link className="banner" to={"/cars"}>
                                <div className="shopping-image">
                                    <img src={`${window.location.origin}/assets/img/slide/new/1.jpg`} alt={"banner champearth"} />
                                </div>

                                <div className="text">
                                    <h4 className='mb-0'>
                                        รถทั้งหมดของเรา
                                    </h4>
                                    <p className='mb-0 text-muted'>description</p>
                                </div>
                            </Link>

                        </div>
                        <div className='col-12 col-md-4 mb-4 banner-item'>
                            <Link className="banner" to={"/blogs"}>
                                <div className="shopping-image">
                                    <img src={`${window.location.origin}/assets/img/slide/new/2.jpg`} alt={"banner champearth"} />
                                </div>

                                <div className="text">
                                    <h4 className='mb-0'>
                                        บทความของเรา
                                    </h4>
                                    <p className='mb-0 text-muted'>description</p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-12 col-md-4 mb-4 banner-item'>
                            <a className="banner" target='_blank' href='https://youtube.com/@champearthmotors3741'>
                                <div className="shopping-image">
                                    <img src={`${window.location.origin}/assets/img/slide/new/3.jpg`} alt={"banner champearth"} />
                                </div>

                                <div className="text">
                                    <h4 className='mb-0'>
                                        Youtube Channel
                                    </h4>
                                    <p className='mb-0 text-muted'>description</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
