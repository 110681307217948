import React, { Component } from 'react'
import Slider from 'react-slick'

export default class AboutUsInfo extends Component {

    render() {
        const list = [
            {
                iconClass: `fas fa-home`,
                title: () => {
                    return <>
                        <h2 className='custom-about-text mb-0 '>Champ Earth Motors ,<span style={{ color: '#333' }}>Cars and Family</span></h2>
                    </>
                },
                subtitle: `"ทำรถ เพราะ ชอบรถ"`,
                description: () => {
                    return (
                        <p className='text-center text-black' style={{ textIndent: 20 }} >
                            พวกเราเป็นคนรุ่นใหม่ที่ชอบรถ ซึ่งประกอบธุรกิจ เกี่ยวกับ การซื้อ-ขาย รถยนต์ มือสอง (premium segment เช่น Benz,BMW,Porsche,อื่นๆ )
                            ในรูปแบบที่พวกเราชอบ เพราะพวกเราก็ชอบรถที่ดี ,สภาพสวย,ใช้งานได้จริง และราคาสมเหตุสมผลเช่นกัน เหมือนลูกค้าทุกท่านครับ
                        </p>
                    )
                },
                imagePath: `${window.location.origin}/assets/img/slide/1.jpg`
            },
            {
                iconClass: `fas fa-home`,
                title: `We`,
                subtitle: `"บุคลากรของพวกเรา"`,
                description: () => {
                    return (
                        <p className='text-left text-black' style={{ textIndent: 20 }} >
                            พนักงานทุกคนที่นี่ โดยเฉพาะพนักงานขาย ลากไปจนถึงเจ้าของ ล้วนแล้วแต่มีประสบการณ์"โชกโชน" และ passion ในงานขายรถแบรนด์หรูหรามาทั้งหมด
                            เพราะฉะนั้นเรื่องข้อมูลเกี่ยวกับตัวรถและการบริการ ไม่ต้องห่วง แต่อย่างใดครับ
                            รับรองว่าประสบการณ์ "แน่นๆ"กันทุกคน
                        </p>
                    )
                },
                imagePath: `${window.location.origin}/assets/img/slide/1.jpg`
            },
            {
                iconClass: `fas fa-home`,
                title: `Our way to select cars`,
                subtitleNewLine: true,
                subtitle: `"หลักเกณฑ์ในการเลือกรถของพวกเรา"`,
                description: () => {
                    return (
                        <p className='text-left text-black' style={{ textIndent: 20 }} >
                            รถทุกคันที่พวกเราเลือกมาซื้อ-ขายนั้น เวลาเลือกมาเปรียบเสมือน "เลือกมาเพื่อซื้อใช้งานเองหรือซื้อให้คนที่เรารัก-คนในครอบครัวใช้" (เอาชนิดที่ว่าถ้าขายไม่ออกก็ใช้กันเองนี่แหละ 555) เพราะฉะนั้น
                            คุณภาพมาก่อนแน่นอนครับและคุ้มกับเงินทุกบาททุกสตางค์ที่คุณจ่ายไปแน่นอน เรื่องชนหนัก,จมน้ำไม่ต้องพูดถึงครับ! ตัดทิ้งได้เลย รถทุกคันมีประวัติให้ดูและตรวจสอบได้แน่นอนครับ
                        </p>
                    )
                },
                imagePath: `${window.location.origin}/assets/img/slide/2.jpg`
            },
            {
                iconClass: `fas fa-home`,
                title: `Our Business core values`,
                subtitle: `"เป้าหมาย และหลักการของเรา"`,
                description: () => {
                    return (
                        <p className='text-left text-black' style={{ textIndent: 20 }} >
                            เราไม่ใช่พ่อค้าที่ ขายถูกที่สุด หรือ ซื้อแพงที่สุด ในประเทศ แต่ เรา สามารถพูดได้เต็มปากว่าสินค้าของเรามีคุณภาพและสมเหตุสมผลในเชิงของราคาแน่นอน
                        </p>
                    )
                },
                imagePath: `${window.location.origin}/assets/img/slide/3.jpg`
            },
            {
                iconClass: `fas fa-home`,
                title: `Our promise`,
                subtitle: `"เราขอสัญญาว่า"`,
                description: () => {
                    return (
                        <p className='text-left text-black' style={{ textIndent: 20 }} >
                            เราขอสัญญาว่า เราจะใช้ประสบการณ์ของพวกเราเพื่อ ดูแลลูกค้าทุกคน ที่ไม่ว่าจะซื้อหรือขายรถกับที่นี่ให้ เกิดเป็นความทรงจำที่ดีดีที่สุด เท่าที่พวกเราจะทำได้ และกลับมาซื้อซ้ำ หรือแนะนำเพื่อนๆ มาอุดหนุนพวกเราใหม่ อิอิ
                        </p>
                    )
                },
                imagePath: `${window.location.origin}/assets/img/slide/4.jpg`
            }
        ]
        const settings = {
            dots: true,
            arrows: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            pauseOnDotsHover: false,
            infinite: true,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 5000,
            cssEase: "linear",
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            initialSlide: 0,
        };
        return (
            <section className='about-us about-us-home pt-0'>
                <div className='container ab-body'>
                    <div className='row justify-content-center'>
                        {
                            list.map((item, index) => {
                                return <React.Fragment key={index}>
                                    <AboutItem item={item} index={index} hideMobile={true} />
                                </React.Fragment>
                            })
                        }
                    </div>
                    <div className='render-mobile'>
                        <Slider
                            {...settings}
                        >
                            {
                                list.map((item, index) => {
                                    if (index == 0) { return }
                                    return (
                                        <React.Fragment key={index}>
                                            <AboutItem item={item} index={index} />
                                        </React.Fragment>
                                    )
                                })
                            }


                        </Slider>
                    </div>
                </div>
            </section >
        )
    }
}
class AboutItem extends React.Component {
    render() {
        let item = this.props.item;
        if (!item) {
            return null
        }
        if (this.props.index == 0) {
            return (
                <div className='col-12 mb-5'>
                    <div className='d-flex justify-content-center align-items-center mt-5'>
                        <img alt={'champearthlogo'} src={`${window.location.origin}/assets/images/champearth.png`} height={150} />
                    </div>
                    <p className='text-center text-black' >
                        <br />
                        {item.title && typeof item.title == 'function' ?
                            item.title()
                            : item.title && typeof item.title != 'function' ?
                                <h2 className='custom-about-text mb-0 '>{item.title} </h2>
                                : null
                        }

                        {item.subtitle ? <h2 className='custom-about-text mb-2 after'><small>{item.subtitle}</small></h2> : null}
                        {item.description && typeof item.description == 'function' ?
                            item.description()
                            : item.description && typeof item.description != 'function' ?
                                item.description
                                : null
                        }
                    </p>
                </div>
            )
        }
        return (
            <div className={`col-12 col-md-6 ${this.props.hideMobile ? "hide-mobile" : null}`}>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-4'>
                        {
                            item.imagePath ?
                                <div className='newcar-card'>
                                    <div className='newcar-card-image'>
                                        <img src={item.imagePath} alt={"banner chamearthmotor"} />
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div className='col-12 col-md-12 col-lg-8'>
                        <p className='text-left text-black' >
                            <br />
                            <h2 className='custom-about-text mb-2 '>{item.title} <br />{item.subtitle ? <small>{item.subtitle}</small> : null}</h2>
                            {item.description && typeof item.description == 'function' ?
                                item.description()
                                : item.description && typeof item.description != 'function' ?
                                    item.description
                                    : null
                            }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
class AboutItemIcon extends React.Component {
    render() {
        let item = this.props.item;
        if (!item) {
            return null
        }
        return (
            <div className='about-box'>
                {
                    item.iconClass ?
                        <div className='d-flex justify-content-center align-items-center'>
                            <i className={item.iconClass} />
                        </div>
                        : null
                }
                <p className='text-center text-black' >
                    <br />
                    <h2 className='custom-about-text mb-0 text='>{item.title} {item.subtitleNewLine ? <br /> : null}{item.subtitle ? <small>{item.subtitle}</small> : null}</h2>
                    {item.description && typeof item.description == 'function' ?
                        item.description()
                        : item.description && typeof item.description != 'function' ?
                            item.description
                            : null
                    }
                    <br />
                </p>
            </div>
        )
    }
}
const mockup = [
    {
        iconClass: `fas fa-home`,
        title: `Our company`,
        subtitle: `"ทำรถ เพราะ ชอบรถ"`,
        description: () => {
            return (
                <>
                    พวกเราเป็นคนรุ่นใหม่ที่ชอบรถ ซึ่งประกอบธุรกิจ เกี่ยวกับ การซื้อ-ขาย รถยนต์ มือสอง (premium segment เช่น Benz,BMW,Porsche,อื่นๆ )<br />
                    ในรูปแบบที่พวกเราชอบ เพราะพวกเราก็ชอบรถที่ดี ,สภาพสวย,ใช้งานได้จริง และราคาสมเหตุสมผลเช่นกัน เหมือนลูกค้าทุกท่านครับ
                </>
            )
        },
    },
    {
        iconClass: `fas fa-home`,
        title: `We`,
        subtitle: `"บุคลากรของพวกเรา"`,
        description: () => {
            return (
                <>
                    พนักงานทุกคนที่นี่ โดยเฉพาะพนักงานขาย ลากไปจนถึงเจ้าของ ล้วนแล้วแต่มีประสบการณ์"โชกโชน" และ passion ในงานขายรถแบรนด์หรูหรามาทั้งหมด<br />
                    เพราะฉะนั้นเรื่องข้อมูลเกี่ยวกับตัวรถและการบริการ ไม่ต้องห่วง แต่อย่างใดครับ
                    รับรองว่าประสบการณ์ "แน่นๆ"กันทุกคน
                </>
            )
        },
    },
    {
        iconClass: `fas fa-home`,
        title: `Our way to select cars`,
        subtitleNewLine: true,
        subtitle: `"หลักเกณฑ์ในการเลือกรถของพวกเรา"`,
        description: () => {
            return (
                <>
                    รถทุกคันที่พวกเราเลือกมาซื้อ-ขายนั้น เวลาเลือกมาเปรียบเสมือน "เลือกมาเพื่อซื้อใช้งานเองหรือซื้อให้คนที่เรารัก-คนในครอบครัวใช้" ( เอาชนิดที่ว่าถ้าขายไม่ออกก็ใช้กันเองนี่แหละ 555 ) เพราะฉะนั้น
                    คุณภาพมาก่อนแน่นอนครับและคุ้มกับเงินทุกบาททุกสตางค์ที่คุณจ่ายไปแน่นอน เรื่องชนหนัก,จมน้ำไม่ต้องพูดถึงครับ! ตัดทิ้งได้เลย รถทุกคันมีประวัติให้ดูและตรวจสอบได้แน่นอนครับ
                </>
            )
        },
    },
    {
        iconClass: `fas fa-home`,
        title: `Our Business core values`,
        subtitle: false,
        description: () => {
            return (
                <>
                    เราไม่ใช่พ่อค้าที่ ขายถูกที่สุด หรือ ซื้อแพงที่สุด ในประเทศ แต่ เรา สามารถพูดได้เต็มปากว่าสินค้าของเรามีคุณภาพและสมเหตุสมผลในเชิงของราคาแน่นอน
                </>
            )
        },
    },
    {
        iconClass: `fas fa-home`,
        title: `Our promise`,
        subtitle: false,
        description: () => {
            return (
                <>
                    เราขอสัญญาว่า เราจะใช้ประสบการณ์ของพวกเราเพื่อ ดูแลลูกค้าทุกคน ที่ไม่ว่าจะซื้อหรือขายรถกับที่นี่ให้ เกิดเป็นความทรงจำที่ดีดีที่สุด เท่าที่พวกเราจะทำได้ และกลับมาซื้อซ้ำ หรือแนะนำเพื่อนๆ มาอุดหนุนพวกเราใหม่ อิอิ
                </>
            )
        },
    }
]