import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from '../../../component/Loading';
import { Link } from '@reach/router';
import Helper from '../../../Helper';


export default class UsersReview extends Component {
  constructor() {
    super();
    this.state = {
      is_loading: true,
      list: []
    }
  }

  async updateReviews(reviews) {
    const PlaceID = `ChIJAfKXcpKd4jARIHsEFpbQ4lg`;
    const GoogleKEY = `AIzaSyCWhF4JJU5jfmilRFvvY7Dw1t4uYrt_YzQ`;
    let res = await fetch(`https://places.googleapis.com/v1/places/${PlaceID}?fields=reviews,displayName&key=${GoogleKEY}`).then((response) => response.json());
    // let res = await fetch(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${PlaceID}&fields=name,reviews&key=${GoogleKEY}`, {mode: 'no-cors'}).then((response) => response.json());
    this.setState({
      is_loading: false,
      list: res.reviews || [],
    })
  }

  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      slidesToShow: 3,
      speed: 500,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 4000,
      cssEase: "linear",
      pauseOnHover: true,
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      arrows: false,
      dots: true,
      draggable: false,
      centerPadding: "0px",
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            slidesToShow: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          }
        }
      ]
    };
    return (
      <section className='review'>
        <div className='container'>
          <h2>ความคิดเห็นของลูกค้า</h2>
          <p className='text-center subtitle'>ขอบคุณทุกความคิดเห็นของลูกค้าทุกท่านที่มีต่อเรา Champearth Motor ขอขอบคุณลูกค้าทุกท่านที่เข้ามาใช้บริการ</p>
        </div>
        <div className='container'>
          {
            this.state.is_loading ?
              <div style={{ paddingTop: 30 }}><Loading /></div>
              : null
          }
          {
            !this.state.is_loading
              && this.state.list.length > 0 ?
              <>
                <Slider {...settings}>
                  {
                    this.state.list.map((item, index) => {
                      let starempty = [];
                      let starfilled = [];
                      if (item.star) {
                        for (let index = 0; index < item.star; index++) {
                          starfilled.push(1);
                        }
                        for (let index = 0; index < (5 - item.star); index++) {
                          starempty.push(1);
                        }

                      }
                      return (
                        <div key={index}>
                          <div className='pl-2 pr-2 pt-5 pb-5 review-item'>
                            <a to={`/review/${item.id}`} href={item.authorAttribution?.uri} target='_blank'>
                              <div className='review-card custom-hover'>
                                <div className='review-body'>
                                  <div className='d-flex justify-content-center align-items-center review-icon mb-3'>
                                    <i className="fa-solid fa-comment-dots"></i>
                                  </div>
                                  <h3>{item.authorAttribution?.displayName}</h3>
                                  <small className='mb-2'>{item.relativePublishTimeDescription}</small>
                                  <p>{item.originalText?.text}</p>
                                  <div className='review-pos'>
                                    <div className='d-flex justify-content-center align-items-center mb-2'>
                                      {
                                        starfilled.map((i, findex) => {
                                          return (<i className="las la-star full" key={findex}></i>)
                                        })
                                      }
                                      {
                                        starempty.map((i, eindex) => {
                                          return (<i className="las la-star empty" key={eindex}></i>)
                                        })
                                      }
                                    </div>
                                    <div className='image-frame'>
                                      <img src={item.authorAttribution?.photoUri || `${window.origin}/assets/media/avatars/blank.png`} className="image" alt={`reviewer`} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      )
                    })
                  }

                </Slider>
                <div className='mt-5 d-flex justify-content-center align-items-center'>
                  <Link to="/reviews" className='button-53'>ดูรีวิว <i className="fa-solid fa-arrow-right"></i></Link>

                </div>
              </>
              : null
          }

        </div>
      </section>
    )
  }
}
