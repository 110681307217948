import React, { Component } from 'react';
import { Link } from '@reach/router';

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-5'>
              {/* <h3>ที่อยู่</h3> */}
              <div className='align-items-center justify-content-center' style={{ display: 'inline-flex', flexDirection: 'column' }}>
                <img src={`${window.location.origin}/assets/images/champearth.png`} className='footer-icon' />
                <h1 className='text-center'>champ earth <small style={{ color: '#E36C78' }}>motors</small></h1>
              </div>
              <p className='mb-0'>ที่อยู่: 151 สีกัน 466 ถ. เทิดราชัน แขวงสีกัน เขตดอนเมือง กรุงเทพมหานคร 10210</p>
              {/* <a href="https://goo.gl/maps/tfKQNvWw9BXdTJo88" target={"_blank"}>
                <i className="fa-solid fa-map-location-dot"></i> Google Map
              </a> */}
            </div>
            <div className='col-12 col-md-4'>
              <h3>ติดต่อ</h3>
              <ul>
                <li>
                  <a href="tel:0991464646" target={"_blank"} className='d-flex'>
                    <div className='mr-1' stlye={{ width: 30 }}><i className="fas fa-phone"></i></div>
                    <span>099-146-4646</span>
                  </a>
                </li>
                <li>
                  <a href="https://lin.ee/2BwZQnijS" target={"_blank"} className='d-flex'>
                    <div className='mr-1' stlye={{ width: 30 }}><i className="fa-brands fa-line"></i></div>
                    <span>@champearthmotors</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/ChampEarthMotors" target={"_blank"} className='d-flex'>
                    <div className='mr-1' stlye={{ width: 30 }}><i className="fa-brands fa-square-facebook"></i></div>
                    <span>ChampEarthMotors</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:champearth.motors169@gmail.com" target={"_blank"} className='d-flex'>
                    <div className='mr-1' stlye={{ width: 30 }}><i className="fa-solid fa-envelope"></i></div>
                    <span>champearth.motors169@gmail.com</span>
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/@champearthmotors3741" target={"_blank"} className='d-flex'>
                    <div className='mr-1' stlye={{ width: 30 }}><i className="fa-brands fa-youtube"></i></div>
                    <span>ChampEarth Motors</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className='col-12 col-md-3'>
              <ul className='footer-menu'>
                <li><Link to="/about">เกี่ยวกับเรา</Link></li>
                <li><Link to="/contact">ติดต่อเรา</Link></li>
                <li><Link to="/terms">นโยบายและข้อกำหนด</Link></li>
                <li><Link to="/policy">ข้อตกลงการใช้งาน</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
